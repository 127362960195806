import React, { useState, useEffect, useRef } from "react";
import "../styles/AddEmployeePage.css";
import "../../../styles/global.scss";
import InputApp from "../../../components/InputApp/InputApp";

const PhoneNumberInput = ({ setPhone, setCode, error }) => {
  const [countryCode, setCountryCode] = useState("+381");
  const [phoneNumber, setPhoneNumber] = useState("");
  const dropdownRef = useRef(null);

  const handleCountryCodeChange = (event) => {
    let value = event;
    if (!value.startsWith("+")) {
      value = `+${value.replace(/[^0-9]/g, "")}`;
    }
    setCountryCode(value);
  };

  const handlePhoneNumberChange = (event) => {
    const regex = /^\d*$/;
    if (regex.test(event)) {
      setPhoneNumber(event);
    }
  };

  useEffect(() => {
    setPhone(phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    setCode(countryCode);
  }, [countryCode]);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex-direction-row gap-12 width-100">
      <div
        className="flex-direction-row align-center autocomplete-container"
        ref={dropdownRef}
      >
        <InputApp
          className="country-code"
          onChange={(event) => handleCountryCodeChange(event)}
          type="text"
          value={countryCode}
          placeholder="+XXX"
          label="Pozivni"
        />
      </div>
      <InputApp
        className="phone-modal-input"
        onChange={(event) => handlePhoneNumberChange(event)}
        type="text"
        value={phoneNumber}
        placeholder="Broj telefona"
        label="Telefon"
        error={error}
      />
    </div>
  );
};

export default PhoneNumberInput;
