import React, { useState } from "react";
import "./styles/attendancePage.scss";
import "../../styles/global.scss";
import ButtonFolder from "../../components/ButtonsApp/ButtonSpecial";
import { icons } from "../../constants";
import { useTheme } from "@mui/material";
import AttendanceContent from "./components/AttendanceContent/AttendanceContent";

const AttendancePage = (props) => {
  const [activeButtons, setActiveButtons] = useState({
    header: "check",
    content: "day",
  });

  const handleButtonClick = (buttonId) => {
    setActiveButtons((prevState) => ({
      ...prevState,
      header: buttonId,
    }));
  };

  const changeClickedButton = (buttonId) => {
    setActiveButtons((prevState) => ({
      ...prevState,
      content: buttonId,
    }));
  };

  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const littleLighter = theme.palette.primary.littleLighter;

  return (
    <div className="flex-direction-column gap-24 align-start width-100 height-100 attendance-page">
      <div
        className="flex-direction-row justify-center align-center gap-24 align-stretch shadows-2-2-8 attendance-page-buttons"
        style={{ backgroundColor: backgroundDefault }}
      >
        {/* <ButtonFolder
          number={200}
          icon={icons.check}
          classNameInside="attendance-check-btn"
          backgroundColor={
            activeButtons.header === "check" ? littleLighter : "transparent"
          }
          title="Prijave"
          onClickButton={() => handleButtonClick("check")}
          disabled={activeButtons.content !== "day"}
        />
        <ButtonFolder
          number={83}
          icon={icons.errorIcon}
          classNameInside="attendance-error-btn"
          title="Bez Prijave"
          onClickButton={() => handleButtonClick("unregistered")}
          backgroundColor={
            activeButtons.header === "unregistered"
              ? littleLighter
              : "transparent"
          }
          disabled={activeButtons.content !== "day"}
        />
        <ButtonFolder
          number={200}
          icon={icons.leave}
          classNameInside="attendance-check-out-btn"
          title="Odjave"
          onClickButton={() => handleButtonClick("checkOut")}
          backgroundColor={
            activeButtons.header === "checkOut" ? littleLighter : "transparent"
          }
          disabled={activeButtons.content !== "day"}
        />
        <ButtonFolder
          number={200}
          icon={icons.holiday}
          classNameInside="attendance-holiday-btn"
          title="Odsustvo"
          onClickButton={() => handleButtonClick("holiday")}
          backgroundColor={
            activeButtons.header === "holiday" ? littleLighter : "transparent"
          }
          disabled={activeButtons.content !== "day"}
        /> */}
      </div>
      <AttendanceContent
        status={activeButtons.header}
        receiveButtonId={(buttonId) => changeClickedButton(buttonId)}
        activeContentButton={activeButtons.content}
      />
    </div>
  );
};

export default AttendancePage;
