import React, { useState, useRef, useEffect } from "react";
import { icons } from "../../../constants";
import { useTheme } from "@mui/material";

const FamilyMemberRow = ({ familyMembers }) => {
  const [showInvisibleFamily, setShowInvisibleFamily] = useState({});

  const handleToggleFamilyMember = (index) => {
    setShowInvisibleFamily((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;

  return (
    <div className="flex-direction-column width-100 gap-12">
      {familyMembers.map((member, index) => (
        <div key={index} className="flex-direction-column gap-12">
          <div className="flex-direction-row gap-12 align-center">
            <p
              className="Grotesk-400 font-16 capitalize"
              style={{ color: neutralMedium }}
            >
              {member.name}
            </p>
            <img
              src={icons.arrow_down}
              alt="icon"
              className={`pointer other-info-arrow ${
                showInvisibleFamily[index] ? "arrow-hidden" : "arrow-visible"
              }`}
              width={16}
              height={7}
              onClick={() => handleToggleFamilyMember(index)}
            />
          </div>
          <div
            ref={(contentRef) => {
              if (contentRef && showInvisibleFamily[index]) {
                contentRef.style.maxHeight = `${contentRef.scrollHeight}px`;
              }
            }}
            className={`transition-wrapper flex-direction-column gap-12 ${
              showInvisibleFamily[index] ? "expanded" : ""
            }`}
            style={{
              maxHeight: showInvisibleFamily[index] ? `400px` : "0px",
              overflowY: "auto",
            }}
          >
            {member.documents.map((document, docIndex) => (
              <div
                key={docIndex}
                className="flex-direction-row align-center gap-12 pointer"
              >
                <img src={icons.filePink} alt="icon" />
                <p
                  className="Grotesk-400 font-16 capitalize underline"
                  style={{ color: neutralMedium }}
                >
                  {document}
                </p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FamilyMemberRow;
