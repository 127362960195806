import React, { useState, useEffect } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./styles/navbar.scss";
import { icons, images } from "../../constants/index.js";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import "../../styles/global.scss";
import { clearUser } from "../../store/userSlice.js";
import UserService from "../../services/userService.js";

import { useSelector } from "react-redux";
import {
  useGetCompanyIdByUserIdQuery,
  useGetCompanyInfoQuery,
} from "../../store/apiSlice";
import Loader from "../../components/Loader/Loader";
import ToastError from "../../components/ToastError/ToastError";
import { roles } from "../../constants";
import { setCompany } from "../../store/companySlice.js";

export const CustomLink = ({
  to,
  presence,
  children,
  src,
  display,
  presenceReview,
  ...props
}) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  const theme = useTheme();
  const littleLighter = theme.palette.primary.littleLighter;
  const neutralMain = theme.palette.neutral.main;

  return (
    <Link
      to={to}
      {...props}
      style={{
        display: display ? display : "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "8px",
        height: "48px",
        padding: "14px",
        gap: "8px",
        backgroundColor: isActive ? littleLighter : "",
        "--hover-background-color": littleLighter || "",
      }}
      // className={isActive ? "active" : ""}
    >
      {!presenceReview ? (
        <img src={src} height="24px" width="24px" />
      ) : (
        <div style={{ width: "24px", height: "24px" }}></div>
      )}

      <div className="flex-direction-column width-100 gap-12">
        <p
          className="Grotesk-500 font-16"
          style={{ color: neutralMain, width: "100%" }}
        >
          {children}
        </p>
      </div>
    </Link>
  );
};

const Navbar = () => {
  const userId = useSelector((state) => state.user.data.userId);
  const isAdmin = UserService.hasRole([roles.company_admin, roles.super_admin]);

  const [photo, setPhoto] = useState(images.profilePicture);
  const dispatch = useDispatch();
  const {
    data: userIdData,
    isLoading: isUserIdLoading,
    error: userIdError,
  } = useGetCompanyIdByUserIdQuery({ id: userId });
  const companyId = userIdData?.data?.employee?.companyId; // Assuming the response contains a `companyId` field.
  useEffect(() => {
    if (userIdData?.data?.employee?.photo) {
      setPhoto(userIdData.data.employee.photo);
    }
    dispatch(setCompany({ companyId }));
  }, [userIdData]);
  const {
    data: companyInfoData,
    isLoading: isCompanyInfoLoading,
    error: companyInfoError,
  } = useGetCompanyInfoQuery(
    { id: companyId },
    {
      skip: !companyId, // Only fetch when companyId is available.
    }
  );

  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const [openPresence, setOpenPresence] = useState(false);

  const handleLogout = async () => {
    UserService.doLogout();
    dispatch(clearUser());
  };

  const { name } = useSelector((state) => state.user.data);

  if (isCompanyInfoLoading || isUserIdLoading) {
    return <Loader />;
  }
  if (companyInfoError || userIdError) {
    console.error(
      "Failed to fetch data:",
      companyInfoError || companyInfoError
    );
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  if (companyInfoData?.data?.errors || userIdData?.data?.errors) {
    console.error(
      "Failed to fetch data:",
      companyInfoData?.data?.errors[0] || userIdData?.data?.errors[0]
    );
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  return (
    <div className="navbar" style={{ backgroundColor: backgroundDefault }}>
      <div className="navbar-avatar">
        <div className="navbar-avatar-items">
          <img
            src={photo}
            className="navbar-avatar-img"
            height="48px"
            width="48px"
          />
          <div className="navbar-avatar-text">
            <p>{name}</p>
            <p
              style={{
                color: "#7A7A7A",
              }}
            >
              {companyInfoData?.data?.company?.shortName}
            </p>
          </div>
        </div>
      </div>
      <div className="navbar-main">
        <div className="navbar-menu">
          <div className="navbar-menu-items" style={{ width: "100%" }}>
            <ul>
              <CustomLink to="/" src={icons.home}>
                Početna
              </CustomLink>
              <CustomLink to="/profile" src={icons.profile}>
                Profil
              </CustomLink>
              {isAdmin && (
                <CustomLink to="/attendance" src={icons.time}>
                  Prisustvo
                </CustomLink>
              )}
              {/* <div className="flex-direction-row align-center justify-space-between width-100 relative">
                <CustomLink to="/attendance" src={icons.time}>
                  Prisustvo
                </CustomLink>
                <img
                  src={!openPresence ? icons.arrow_down : icons.arrow_up}
                  width={18}
                  height={7}
                  onClick={() => {
                    setOpenPresence(!openPresence);
                  }}
                  className="pointer presence-arrow"
                />
              </div>
              <CustomLink
                to="/presence"
                display={!openPresence ? "none" : "flex"}
                presenceReview
              >
                Pregled prisustva
              </CustomLink> */}
              {isAdmin && (
                <CustomLink to="/employees" src={icons.employees}>
                  Zaposleni
                </CustomLink>
              )}
              {/* <CustomLink to="/requests" src={icons.requests}>
                Zahtevi
              </CustomLink>
              <CustomLink to="/notifications" src={icons.notifications}>
                Obaveštenja
              </CustomLink>
              <CustomLink to="/settings" src={icons.settings}>
                Podešavanja
              </CustomLink> */}
            </ul>
          </div>
        </div>
        <div
          className="navbar-menu-items"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CustomLink to="/login" src={icons.log_out} onClick={handleLogout}>
            Log out
          </CustomLink>
          {/* <CustomLink to="/help" src={icons.help}>
            Pomoć
          </CustomLink>
          <CustomLink to="/building" src={icons.building}>
            <div className="flex-direction-row align-center justify-space-between width-100">
              Midmeding Nova
              <img src={icons.arrow_right} width={18} height={16} />
            </div>
          </CustomLink> */}
        </div>
      </div>
    </div>
  );
};
export default Navbar;
