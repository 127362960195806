import React, { useState } from "react";
import "../styles/homepage.scss";
import { useTheme } from "@mui/material";

const TimeButton = ({ title, color, onClick }) => {
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const neutralMedium = theme.palette.neutral.medium;
  return (
    <div
      className="time-button-main div-white-shadows"
      style={{
        border: `2px solid ${color}`,
        backgroundColor: backgroundDefault,
      }}
      onClick={onClick}
    >
      <div className="time-button-central">
        <p
          className="Grotesk-400"
          style={{
            color: neutralMedium,
          }}
        >
          {title}
        </p>
        <div
          style={{
            height: "20px",
            width: "60px",
            borderRadius: "8px",
            backgroundColor: color,
          }}
        />
      </div>
    </div>
  );
};
export default TimeButton;
