// color design tokens export
export const colorTokens = {
  grey: {
    0: "#FFFFFF",
    100: "#FDFDFD",
    150: "#E1E1E1",
    200: "#C8C8C8",
    250: "#B2B2B2",
    300: "#A0A0A0",
    400: "#818181",
    425: "#7B7272",
    450: "#787878",
    500: "#3d3d3d",
    550: "#323232",
    600: "#282828",
    700: "#1F2025",
    1000: "#000000",
  },
  primary: {
    0: "#FFFFFF",
    25: "#F5F5F5",
    50: "#F2EBF5",
    75: "#F2EAFD",
    100: "#E2CEEA",
    200: "#D7A2D8",
    300: "#CB99E0",
    400: "#9860B1",
    450: "#883CEF",
    500: "#863F87",
    550: "#76418B",
    600: "#744389",
    700: "#312388",
    750: "#291E69",
    770: "#242648",
    800: "#201850",
    850: "#1d1068",
    875: "#170031",
    900: "#101128",
    950: "#04051B",
    1000: "#000000",
  },
};

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              almostBlack: colorTokens.primary[450],
              moreDarkest: colorTokens.grey[200],
              darkest: colorTokens.primary[0],
              darker: colorTokens.primary[0],
              main: colorTokens.primary[0],
              dark: colorTokens.primary[0],
              medium: colorTokens.primary[800],
              light: colorTokens.primary[750],
              lighter: colorTokens.primary[850],
              littleLighter: colorTokens.primary[700],
              lightest: colorTokens.primary[850],
              moreLightest: colorTokens.primary[770],
            },
            neutral: {
              darker: colorTokens.grey[0],
              dark: colorTokens.grey[0],
              medium: colorTokens.grey[0],
              lighterMedium: colorTokens.grey[0],
              main: colorTokens.grey[0],
              light: colorTokens.grey[150],
              lighter: colorTokens.grey[250],
            },
            background: {
              default: colorTokens.primary[950],
              alt: colorTokens.primary[900],
            },
          }
        : {
            // palette values for light mode
            primary: {
              almostBlack: colorTokens.primary[875],
              moreDarkest: colorTokens.primary[770],
              darkest: colorTokens.primary[600],
              darker: colorTokens.primary[550],
              main: colorTokens.primary[500],
              dark: colorTokens.primary[400],
              medium: colorTokens.primary[300],
              light: colorTokens.primary[200],
              lighter: colorTokens.primary[100],
              littleLighter: colorTokens.primary[75],
              lightest: colorTokens.primary[50],
              moreLightest: colorTokens.primary[25],
            },
            neutral: {
              darker: colorTokens.grey[1000],
              dark: colorTokens.grey[600],
              medium: colorTokens.grey[700],
              lighterMedium: colorTokens.grey[550],
              main: colorTokens.grey[500],
              light: colorTokens.grey[450],
              lighter: colorTokens.grey[425],
            },
            background: {
              default: colorTokens.grey[0],
              alt: colorTokens.grey[0],
            },
          }),
    },
  };
};
