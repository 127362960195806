import React, { useState, useEffect } from "react";
import "./styles/AddEmployeePage.css";
import "../../styles/global.scss";
import { useTheme } from "@mui/material";
import ButtonApp from "../../components/ButtonsApp/ButtonRegular";
import InputApp from "../../components/InputApp/InputApp";
import { icons } from "../../constants";
import Dropdown from "../../components/Dropdown/Dropdown";
import { useNavigate } from "react-router-dom";
import ImageDropzone from "../../components/UploadCropImage/components/ImageDropzone";
import PhoneNumberInput from "./components/PhoneNumberInput";
import { validateString } from "../../utils/validateString";

import {
  useCreateEmployeeMutation,
  useGetPositionsAndSectorsQuery,
  useCreateDocumentMutation,
  // useUploadFileMutation,
  useConfirmDocumentMutation,
} from "../../store/apiSlice";
import uploadFile from "../../hooks/uploadFile";
import { useConfirmation } from "../../components/ConfirmationModal/ConfirmationModal";
import Loader from "../../components/Loader/Loader";
import ToastError from "../../components/ToastError/ToastError";
import { useSelector } from "react-redux";

const AddEmployeePage = () => {
  const companyId = useSelector((state) => state.company.data.companyId);
  const [image, setImage] = useState();
  const [sectors, setSectors] = useState([]);
  const [positions, setPositions] = useState([]);
  const [employeeObject, setEmployeeObject] = useState({
    name: "",
    surname: "",
    middleName: "",
    gender: "",
    birthday: "",
    email: "",
    address: "",
    positionId: "",
    sectorId: "",
    // work_location: "",
    phone: "",
    uniqueCitizenNumber: "",
    nationalCardNumber: "",
    passportNumber: "",
    countryCode: "",
    nationality: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const {
    data: positionsAndSectorsData,
    error: positionsAndSectorsError,
    isLoading: positionsAndSectorsLoading,
  } = useGetPositionsAndSectorsQuery({
    id: companyId || "",
  });

  const [createEmployee] = useCreateEmployeeMutation();
  const [createDocument] = useCreateDocumentMutation();
  // const [uploadFile] = useUploadFileMutation();
  const [confirmDocument] = useConfirmDocumentMutation();

  const [confirm, ConfirmationModalComponent] = useConfirmation();

  const setInputValue = (key, value) => {
    setEmployeeObject((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const validateAllFields = () => {
    let hasError = false;

    setErrors({});

    Object.entries(employeeObject).forEach(([key, value]) => {
      let err = false;

      if (!value) {
        err = true;
      } else {
        switch (key) {
          case "email":
            err = !validateString(value, "e_mail");
            break;
          case "uniqueCitizenNumber":
          case "passportNumber":
          case "nationalCardNumber":
            err = !validateString(value, "number");
            break;
          default:
            err = typeof value === "string" && value.trim() === "";
            break;
        }
      }

      if (err) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: true,
        }));
        hasError = true;
      }
    });

    return !hasError;
  };
  const theme = useTheme();
  const defaultBackground = theme.palette.background.default;
  const neutralMedium = theme.palette.neutral.medium;
  const neutralMain = theme.palette.neutral.main;

  const navigate = useNavigate();

  useEffect(() => {
    if (positionsAndSectorsData) {
      setSectors(positionsAndSectorsData?.data?.sectors);
      setPositions(positionsAndSectorsData?.data?.positions);
    }
  }, [positionsAndSectorsData]);

  const onCancelClick = () => {
    navigate("/employees");
  };

  const onSaveClick = () => {
    const isValid = validateAllFields();
    if (!isValid) {
      console.log(errors);
      return;
    }
    console.log("employee", employeeObject);
    handleCreateEmployee();
  };
  const handleCreateEmployee = async () => {
    const message = `Da li ste sigurni?`;
    const result = await confirm(message);
    if (!result) {
      return;
    }
    try {
      setIsLoading(true);
      //first creating the employee to get its ID
      console.log("creating employee...");
      const { gender, countryCode, ...input } = employeeObject;
      let employeeResponse = await createEmployee({
        input: {
          ...input,
          companyId: companyId || "",
          positionId: Number.parseInt(input.positionId.id),
          sectorId: Number.parseInt(input.sectorId.id),
          phone: `${countryCode}${input.phone}`,
          gender: gender.id == 1 ? "MALE" : "FEMALE",
        },
      }).unwrap();
      if (employeeResponse?.errors) {
        throw new Error(employeeResponse?.errors[0]);
      }
      const employeeId = employeeResponse?.data?.createEmployee?.id;
      console.log("employeeId", employeeId);
      if (!employeeId) {
        throw new Error("Employee ID not found after creating employee.");
      }
      if (image) {
        //if there is an image uploaded we create document type of PHOTO to get presigned url
        console.log("creating document...");
        console.log(image.name, image.size);
        let documentResponse = await createDocument({
          input: {
            type: "PHOTO",
            name: image.name,
            size: image.size,
            companyId: companyId || "",
            employeeId: employeeId,
          },
        });
        if (documentResponse?.errors) {
          throw new Error(documentResponse?.errors[0]);
        }
        const { id: documentId, url: documentPresignedUrl } =
          documentResponse?.data?.data?.createDocument;
        if (!documentId || !documentPresignedUrl) {
          throw new Error("There is now photo data");
        }
        //uploading the image to the presigned url
        console.log("uploading file...");
        const uploadResponse = await uploadFile(image, documentPresignedUrl);
        console.log("uploadResponse", uploadResponse);
        // if (uploadResponse?.errors) {
        //   throw new Error(uploadResponse?.errors[0]);
        // }
        //if everything is okay we confirm upload to our backend
        const confirmResponse = await confirmDocument({
          id: documentId,
        });
        if (confirmResponse?.errors) {
          throw new Error(confirmResponse?.errors[0]);
        }
      }
      navigate("/employees");
    } catch (err) {
      setFetchError(err);
      console.error("Failed to create employee:", err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading || positionsAndSectorsLoading) {
    return <Loader />;
  }
  if (fetchError || positionsAndSectorsError) {
    console.error(
      "Failed to fetch data:",
      fetchError || positionsAndSectorsError
    );
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  if (positionsAndSectorsData?.errors) {
    let err = positionsAndSectorsData?.errors;
    console.error("Failed to fetch data:", err[0]);
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  return (
    <div className="flex-direction-column gap-24 width-100 add-employee-page">
      {/* <div
        className="flex-direction-row gap-12 justify-center align-center align-stretch width-100 add-employee-search-buttons"
        style={{ backgroundColor: defaultBackground }}
      >
        <InputApp
          className="add-employee-search"
          placeholder="search"
          firstIcon={icons.search}
          firstIconReplacement={icons.searchPurple}
        />
        <ButtonApp
          title="Radna jedinica"
          className="add-employee-workplace-unit"
          textColor={neutralMedium}
          iconFirst={icons.building}
          padding="14px"
        />
      </div> */}
      <div
        className="flex-direction-column gap-24 align-center align-stretch width-100 add-employee-form"
        style={{ backgroundColor: defaultBackground }}
      >
        <div className="flex-direction-column gap-24 align-start align-stretch">
          <p
            className="Grotesk-500 font-16 weight-500"
            style={{ color: neutralMedium }}
          >
            Formular za dodavanje novog zaposlenog
          </p>
          <div className="flex-direction-column gap-12 align-start align-stretch">
            <ImageDropzone onImageDrop={(file) => setImage(file)} />
            <p
              className="Grotesk-500 font-16 weight-400"
              style={{ color: neutralMain }}
            >
              Fotografija
            </p>
          </div>
          <div className="flex-direction-row gap-24 width-100 add-employee-form-content">
            <div className="flex-direction-column gap-24 flex-1-0-0 add-employee-form-content-column">
              <InputApp
                label="Ime"
                className="add-employee-form-input-wrapper"
                placeholder="Marko"
                onChange={(value) => setInputValue("name", value)}
                value={employeeObject.name}
                error={errors.name}
              />
              <InputApp
                label="Ime Oca"
                className="add-employee-form-input-wrapper"
                placeholder="Dragan"
                onChange={(value) => setInputValue("middleName", value)}
                value={employeeObject.middleName}
                error={errors.middleName}
              />
              <InputApp
                label="Drzavljanstvo"
                className="add-employee-form-input-wrapper"
                onChange={(value) => setInputValue("nationality", value)}
                value={employeeObject.nationality}
                error={errors.nationality}
              />
              <Dropdown
                options={options2}
                label="Pol"
                setSelectedOption={(value) => setInputValue("gender", value)}
                selectedOption={employeeObject.gender}
                error={errors.gender}
              />
              <InputApp
                label="Datum Rodjenja"
                className="add-employee-form-input-wrapper"
                placeholder="23.03.1987."
                onChange={(value) => setInputValue("birthday", value)}
                value={employeeObject.birthday}
                type="date"
                error={errors.birthday}
              />
              <PhoneNumberInput
                className="add-employee-form-input-wrapper"
                setPhone={(value) => setInputValue("phone", value)}
                setCode={(value) => setInputValue("countryCode", value)}
                error={errors.phone}
              />
              <InputApp
                label="Email"
                className="add-employee-form-input-wrapper"
                placeholder="markomarkovic@gmail.com"
                onChange={(value) => setInputValue("email", value)}
                value={employeeObject.email}
                error={errors.email}
              />
              <InputApp
                label="Adresa"
                className="add-employee-form-input-wrapper"
                placeholder="Narodnih heroja 91"
                onChange={(value) => setInputValue("address", value)}
                value={employeeObject.address}
                error={errors.address}
              />
            </div>
            <div className="flex-direction-column gap-24 flex-1-0-0 add-employee-form-content-column">
              <InputApp
                label="Prezime"
                className="add-employee-form-input-wrapper"
                placeholder="Petrovic"
                onChange={(value) => setInputValue("surname", value)}
                value={employeeObject.surname}
                error={errors.surname}
              />
              <InputApp
                label="JMBG"
                className="add-employee-form-input-wrapper"
                placeholder="1238281923891"
                onChange={(value) =>
                  setInputValue("uniqueCitizenNumber", value)
                }
                value={employeeObject.uniqueCitizenNumber}
                error={errors.uniqueCitizenNumber}
              />
              <InputApp
                label="Broj licne karte"
                className="add-employee-form-input-wrapper"
                placeholder="45678"
                onChange={(value) => setInputValue("nationalCardNumber", value)}
                value={employeeObject.nationalCardNumber}
                error={errors.nationalCardNumber}
              />
              <InputApp
                label="Broj pasosa"
                className="add-employee-form-input-wrapper"
                placeholder="45678"
                onChange={(value) => setInputValue("passportNumber", value)}
                value={employeeObject.passportNumber}
                error={errors.passportNumber}
              />
              <Dropdown
                options={sectors}
                label="Sektor rada"
                setSelectedOption={(value) => setInputValue("sectorId", value)}
                selectedOption={employeeObject.sectorId}
                error={errors.sectorId}
              />
              {/* <Dropdown
                options={options1}
                label="Lokacija rada"
                setSelectedOption={(value) =>
                  setInputValue("work_location", value)
                }
                selectedOption={employeeObject.work_location}
                error={errors.work_location}
              /> */}
              <Dropdown
                options={positions}
                label="Radna Pozicija"
                setSelectedOption={(value) =>
                  setInputValue("positionId", value)
                }
                selectedOption={employeeObject.positionId}
                error={errors.positionId}
              />
            </div>
          </div>
        </div>
        <div className="flex-direction-row gap-12 align-center add-employee-buttons">
          <ButtonApp
            title="Otkaži"
            width="218.5px"
            onButtonClick={onCancelClick}
          />
          <ButtonApp
            title="Sačuvaj"
            purple
            width="218.5px"
            onButtonClick={onSaveClick}
          />
        </div>
      </div>
      {ConfirmationModalComponent}
    </div>
  );
};

export default AddEmployeePage;

const options2 = [
  { id: "1", name: "Muski" },
  { id: "2", name: "Zenski" },
];
