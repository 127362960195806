import React, { useState } from "react";
import "../SettingsPage/styles/settingspage.scss";
import {
  useGetEmployeesQuery,
  useGetAttendancesQuery,
  useCreateCompanyMutation,
  useCreateEmployeeMutation,
} from "../../store/apiSlice";
import ButtonApp from "../../components/ButtonsApp/ButtonRegular";

const HelpPage = () => {
  const variables = {
    page: 0,
    size: 10,
    companyId: "0dd1ca5e-a457-47fe-8834-3e7e8c40ee38",
  };

  const [formData, setFormData] = useState({
    name: "Petra",
    middleName: "Arnold",
    surname: "Doe",
    email: "caba.doe@example.com",
    phone: "+1-367-789-4560",
    address: "456 Elm Street, Chicago, IL 60611",
    uniqueCitizenNumber: "128251500",
    nationalCardNumber: "1005456700",
    companyId: "0dd1ca5e-a457-47fe-8834-3e7e8c40ee38",
  });

  const company = {
    name: "Global Innovations Inc.",
    slug: "global-innovations",
    email: "info@globalinnovations.com",
    phone: "+1-800-999-4567",
    address: "789 Enterprise Ave, New York, NY, 10001",
    taxId: "987-65-4321",
    registrationNumber: "1234567890",
    shortName: "GlobInnov",
    id: "0dd1ca5e-a457-47fe-8834-3e7e8c40ee38",
  };

  const { data } = useGetEmployeesQuery({ variables });
  const [createCompany, { isLoading }] = useCreateCompanyMutation();
  const [createEmployee] = useCreateEmployeeMutation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleCreateEmployee = async () => {
    try {
      const response = await createEmployee({
        input: formData,
      });
      console.log("Company created successfully:", response);
    } catch (err) {
      console.error("Failed to create company:", err);
    }
  };

  console.log(data);

  return (
    <div className="settingspage-main div-white-r24-shadows">
      <ButtonApp title="Uredi" purple onButtonClick={handleCreateEmployee} />
    </div>
  );
};

export default HelpPage;
