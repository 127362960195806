import React, { useState, useEffect } from "react";
import "../styles/EmployeesPage.css";
import "../../../styles/global.scss";
import { useTheme } from "@mui/material";
import InputApp from "../../../components/InputApp/InputApp";
import { icons } from "../../../constants";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import EmployeesRow from "./EmployeesRow";
import { useNavigate } from "react-router-dom";

import { useGetListOfEmployeesQuery } from "../../../store/apiSlice";
import Loader from "../../../components/Loader/Loader";
import ToastError from "../../../components/ToastError/ToastError";
import { useSelector } from "react-redux";

const EmployeesTable = ({}) => {
  const [employeesArray, setEmployeesArray] = useState([]);
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const navigate = useNavigate();

  const onButtonClick = () => {
    navigate("/employees/add");
  };
  const companyData = useSelector((state) => state.company.data);
  const companyId = companyData?.companyId || null;

  const { data, error, isLoading } = useGetListOfEmployeesQuery(
    { id: companyId },
    {
      skip: !companyId,
    }
  );

  useEffect(() => {
    if (data) {
      const employees = data?.data?.employees?.content;
      if (employees) {
        const array = [];
        for (const employee of employees) {
          const e = {
            name: employee.user.name + " " + employee.user.surname,
            email: employee.user.email,
            phone: employee.user.phone,
            position: employee.position?.name || "",
            photo: employee.photo,
            id: employee.user.id,
          };
          array.push(e);
        }
        setEmployeesArray(array);
      }
    }
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    console.error("Failed to fetch data:", error);
    return <ToastError message={"Došlo je do greške. Pokušajte ponovo!"} />;
  }
  return (
    <div
      className="flex-direction-column gap-24  align-end align-stretch employees-table"
      style={{ backgroundColor: backgroundDefault }}
    >
      <div className="flex-direction-row gap-12 align-stretch align-center employees-table-btn-search">
        {/* <InputApp
          className="employees-table-search"
          placeholder="search"
          firstIcon={icons.search}
          firstIconReplacement={icons.searchPurple}
        /> */}
        <div
          className="flex-direction-row gap-12 align-stretch"
          style={{ justifyContent: "end", width: "100%" }}
        >
          <div style={{ width: "287px", height: "100%" }}></div>
          <ButtonApp
            title="Dodaj zaposlenog"
            width="fit-content"
            // className="employees-table-add-btn"
            purple
            onButtonClick={onButtonClick}
          />
        </div>
      </div>
      <div className="flex-direction-column gap-12 width-100 employees-table-content">
        <div className="flex-direction-row gap-12 align-stretch align-center employees-table-header">
          <div className="employees-row-picture-name">
            <p className="Grotesk-500 font-14">Zaposleni</p>
          </div>
          <div
            className="employees-row-data flex-direction-row gap-48"
            style={{ paddingRight: "24px" }}
          >
            <div className="employees-row-value">
              <p className="Grotesk-500 font-14">Telefon</p>
            </div>
            <div className="employees-row-value">
              <p className="Grotesk-500 font-14">Pozicija</p>
            </div>
            <div className="employees-row-value">
              <p className="Grotesk-500 font-14">Sektor rada</p>
            </div>
            <div className="employees-row-value">
              <p className="Grotesk-500 font-14">Status</p>
            </div>
          </div>
        </div>
        {employeesArray.map((employee, index) => (
          <EmployeesRow key={index} employee={employee} />
        ))}
      </div>
    </div>
  );
};

export default EmployeesTable;

// const EmployeesArray = [
//   {
//     name: "Marko Gvozdenović",
//     email: "markogvozdenovic@gmail.com",
//     id: "2244756469",
//     position: "Frontend developer",
//     jobSector: "Odeljak Beograd",
//     status: "Određeno 6",
//   },
//   {
//     name: "Milan Stanković Milan StankovićMilan Stanković Milan Stanković",
//     email:
//       "markogvozdenovic@gmail.commarkogvozdenovic@gmail.com markogvozdenovic@gmail.com",
//     id: "22447564692244756 4692244756469 2244756469",
//     position: "Frontend developer Frontend developer Frontend developer",
//     jobSector: "Odeljak Beograd Odeljak BeogradOdeljak BeogradOdeljak Beograd",
//     status: "Određeno 6 Određeno 6 Određeno 6 Određeno 6",
//   },
//   {
//     name: "Milan Stanković",
//     email: "markogvozdenovic@gmail.com",
//     id: "2244756469",
//     position: "Frontend developer",
//     jobSector: "Odeljak Beograd",
//     status: "Određeno 6",
//   },
//   {
//     name: "Milan Stanković",
//     email: "markogvozdenovic@gmail.com",
//     id: "2244756469",
//     position: "Frontend developer",
//     jobSector: "Odeljak Beograd",
//     status: "Određeno 6",
//   },
//   {
//     name: "Milan Stanković",
//     email: "markogvozdenovic@gmail.com",
//     id: "2244756469",
//     position: "Frontend developer",
//     jobSector: "Odeljak Beograd",
//     status: "Određeno 6",
//   },
//   {
//     name: "Milan Stanković",
//     email: "markogvozdenovic@gmail.com",
//     id: "2244756469",
//     position: "Frontend developer",
//     jobSector: "Odeljak Beograd",
//     status: "Određeno 6",
//   },
//   {
//     name: "Milan Stanković",
//     email: "markogvozdenovic@gmail.com",
//     id: "2244756469",
//     position: "Frontend developer",
//     jobSector: "Odeljak Beograd",
//     status: "Određeno 6",
//   },
//   {
//     name: "Milan Stanković",
//     email: "markogvozdenovic@gmail.com",
//     id: "2244756469",
//     position: "Frontend developer",
//     jobSector: "Odeljak Beograd",
//     status: "Određeno 6",
//   },
//   {
//     name: "Milan Stanković",
//     email: "markogvozdenovic@gmail.com",
//     id: "2244756469",
//     position: "Frontend developer",
//     jobSector: "Odeljak Beograd",
//     status: "Određeno 6",
//   },
// ];
