import React, { useState } from "react";
import { icons } from "../../constants";
import "./styles/filter.scss";
import "../../styles/global.scss";

const Filter = (props) => {
  const [showModal, setShowModal] = useState(false);
  const options = [
    { img: icons.filter1, text: "New" },
    { img: icons.filter2, text: "Older" },
    { img: icons.filter3, text: "A-Z" },
    { img: icons.filter4, text: "Z-A" },
    { img: icons.filter5, text: "Type" },
    { img: icons.filter6, text: "Larger" },
    { img: icons.filter7, text: "Smaller" },
  ];
  return (
    <div className="flex-direction-row gap-8 filter">
      <div
        className={`flex-direction-column align-start filter-options ${
          showModal ? "visible" : ""
        }`}
        style={{ color: "#999" }}
      >
        {options.map((option, index) => (
          <>
            <div className="flex-direction-row align-center align-stretch gap-10 filter-option pointer">
              <img src={option.img} alt="filter" />
              <p className="Grotesk font-16 weight-400">{option.text}</p>
            </div>
            {index < options.length - 1 && <div className="filter-line"></div>}
          </>
        ))}
      </div>

      <img
        src={icons.filter}
        alt="filters"
        className="pointer filter-picture"
        onClick={() => setShowModal(!showModal)}
      />
    </div>
  );
};

export default Filter;
