import React from "react";
import "../styles/homepage.scss";
import { useTheme } from "@mui/material";

const Notification = (props) => {
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const neutralMedium = theme.palette.neutral.medium;
  return (
    <div className="flex-direction-row gap-8 align-stretch notification">
      <div className="flex-direction-row align-center justify-center notification-image">
        <img src={props.image} alt="" width={28} height={28} />
      </div>
      <div className="flex-direction-column gap-2 align-start width-100 notification-text">
        <div className="flex-direction-row justify-space-between align-stretch date-title">
          <p
            className="Grotesk-400 font-16"
            style={{ lineHeight: "20px", color: neutralMedium }}
          >
            {props.title}
          </p>
          <p
            className="Grotesk-400 font-10"
            style={{ lineHeight: "20px", color: "#818181" }}
          >
            {props.date}
          </p>
        </div>
        <p
          className="Grotesk-400 font-12"
          style={{ lineHeight: "16px", color: neutralMedium }}
        >
          {props.text}
        </p>
      </div>
    </div>
  );
};

export default Notification;
