import React, { useMemo } from "react";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./styles/theme";

import AppRoutes from "./pages/Routes";
import Navbar from "./components/Navbar/Navbar";
import NavbarMobile from "./components/NavbarMobile/NavbarMobile";

import "./styles/app.scss";

function App() {
  const mode = "light";
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return (
    <div className="app-container">
      <ThemeProvider theme={theme}>
        <Navbar />
        <div className="content">
          <NavbarMobile />
          <AppRoutes />
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
