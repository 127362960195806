import React, { useState } from "react";
import "../styles/homepage.scss";
import { icons } from "../../../constants";
import { useTheme } from "@mui/material";

const TableRow = ({ item, className, onClick, clickedRow }) => {
  const theme = useTheme();
  const backgroundAlt = theme.palette.background.alt;
  const lighterMedium = theme.palette.neutral.lighterMedium;
  return (
    <div
      className={`timetable-row div-white-shadows pointer time-table-row ${
        clickedRow === item._id ? "clicked" : ""
      }`}
      style={{ backgroundColor: backgroundAlt, color: lighterMedium }}
      onClick={() => onClick(item._id)}
    >
      <div
        style={{
          display: "flex",
        }}
        className="timetable-row-inside"
      >
        <p className="Grotesk-400 font-16" style={{ width: "120px" }}>
          {item.type}
        </p>
        <p className="Grotesk-400 font-16" style={{ width: "80px" }}>
          {item.time}
        </p>
        <p className="Grotesk-400 font-16">{item.address}</p>
      </div>
      <span
        style={{
          width: "60px",
          height: "20px",
          borderRadius: "8px",
        }}
        className={className}
      ></span>
    </div>
  );
};

const TimeTable = ({ title, color, onRowClick, clickedRow, list }) => {
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  return (
    <div className="timetable-main">
      <div className="timetable-header" style={{ color: neutralMedium }}>
        <div className="timetable-header-text">
          <p className="Grotesk-500 font-16" style={{ width: "100px" }}>
            Tip
          </p>
        </div>
        <div className="timetable-header-text">
          <p className="Grotesk-500 font-16" style={{ width: "64px" }}>
            Vreme
          </p>
        </div>
        <div className="timetable-header-text">
          <p className="Grotesk-500 font-16">Mesto</p>
        </div>
      </div>
      <div className="timetable-content">
        {list.map((item, index) => (
          <TableRow
            key={item._id}
            item={item}
            className={item.type.toLowerCase().replace(/ /g, "-")}
            onClick={(value) => onRowClick(value)}
            clickedRow={clickedRow}
          />
        ))}
      </div>
    </div>
  );
};
export default TimeTable;
