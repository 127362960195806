import React, { useState } from 'react';
import "../styles/settingspage.scss"
import { icons } from "../../../constants";

function ThemeSelector() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSwitch = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className='theme-selector'>
        <div className="theme-selector-title">
                {"Kolorni mod"}
        </div>
        <div className='theme-selector-buttons'>
            <div
                className={activeIndex === 0 ? 'theme-selector-button theme-selector-button-active' : 'theme-selector-button'}
                onClick={() => handleSwitch(0)}
            >
                <img src={icons.sun1}></img>
                <p>Svetla</p>
            </div>
            <div
                className={activeIndex === 1 ? 'theme-selector-button theme-selector-button-active' : 'theme-selector-button'}
                onClick={() => handleSwitch(1)}
            >
                <img src={icons.moon1}></img>
                <p>Tamna</p>
            </div>
        </div>
      
    </div>
  );
}

export default ThemeSelector;