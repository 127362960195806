import React from "react";
import "../styles/EmployeesPage.css";
import "../../../styles/global.scss";
import { images, icons } from "../../../constants";
import { useTheme } from "@mui/material";

import { useNavigate } from "react-router-dom";

const EmployeesRow = ({ employee }) => {
  const navigate = useNavigate();
  const handleOpenUserProfile = (id) => {
    navigate(`/profile/${id}`);
  };
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  const neutralLighterMedium = theme.palette.neutral.light;

  return (
    <div className="flex-direction-row gap-48 employees-row">
      <div className="flex-direction-row gap-8 align-center employees-row-picture-name">
        <div style={{ width: "24px", height: "24px", borderRadius: "100%" }}>
          <img
            src={employee.photo ? employee.photo : images.avatar}
            alt="slika"
            style={{ width: "100%", height: "100%", borderRadius: "inherit" }}
          />
        </div>
        <div className="flex-direction-column gap-2 justify-center align-start">
          <p className="Grotesk-400 font-14" style={{ color: neutralMedium }}>
            {employee.name}
          </p>
          <p
            className="Grotesk-400 font-14"
            style={{ color: neutralLighterMedium }}
          >
            {employee.email}
          </p>
        </div>
      </div>
      <div className="flex-direction-row gap-48 employees-row-data">
        <div className="flex-direction-row align-center employees-row-value">
          <p className="Grotesk-400 font-14" style={{ color: neutralMedium }}>
            {employee.phone}
          </p>
        </div>
        <div className="flex-direction-row align-center employees-row-value">
          <p className="Grotesk-400 font-14" style={{ color: neutralMedium }}>
            {employee.position}
          </p>
        </div>
        <div className="flex-direction-row align-center employees-row-value">
          <p className="Grotesk-400 font-14" style={{ color: neutralMedium }}>
            {/* {employee.jobSector} */} "/"
          </p>
        </div>
        <div className="flex-direction-row align-center employees-row-value">
          <p className="Grotesk-400 font-14" style={{ color: neutralMedium }}>
            {/* {employee.status} */} "/"
          </p>
        </div>
        <div
          className="flex-direction-row align-center pointer"
          onClick={() => {
            handleOpenUserProfile(employee.id);
          }}
        >
          <img src={icons.more} width={24} height={24} />
        </div>
      </div>
    </div>
  );
};

export default EmployeesRow;
