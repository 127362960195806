import React, { useState } from "react";
import ButtonApp from "../../../../../components/ButtonsApp/ButtonRegular";
import { icons } from "../../../../../constants";
import TimeTable from "../../../../HomePage/components/TimeTable";
import "../../../styles/attendancePage.scss";
import AddActionModal from "./AddActionModal";
import ApexChart from "./ApexChart";

const WorkHoursEditor = ({ list, employeeId, date }) => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [rowId, setRowId] = useState();
  const selectedRow = rowId ? list.find((item) => item._id === rowId) : null;
  return (
    <div className="flex-direction-row align-start gap-12 align-stretch workHours-editor">
      {addModal ? (
        <AddActionModal
          handleCloseClick={() => setAddModal(false)}
          employeeId={employeeId}
        />
      ) : editModal ? (
        <AddActionModal
          handleCloseClick={() => setEditModal(false)}
          data={selectedRow}
          editing
          id={rowId}
          date={date}
        />
      ) : (
        <div className="flex-direction-column align-start gap-12 workHours-editor-gauge-buttons">
          <div
            className="flex-direction-column align-center align-stretch"
            style={{
              borderRadius: "24px",
              boxShadow: "0px 0px 8px -2px rgba(31, 32, 37, 0.24)",
              height: "263px",
            }}
          >
            <ApexChart />
          </div>
          <div className="flex-direction-row align-start gap-12 align-stretch workHours-editor-buttons">
            <ButtonApp
              title="Dodaj akciju"
              icon={icons.add}
              className="workHours-editor-btn"
              onButtonClick={() => setAddModal(true)}
            />
            <ButtonApp
              title="Izmeni akciju"
              icon={icons.pencil}
              className="workHours-editor-btn"
              onButtonClick={() => rowId && setEditModal(true)}
            />
          </div>
        </div>
      )}

      <div className="flex-direction-column gap-24 workHours-editor-data">
        <div className="flex-direction*column align-start gap-12 align-stretch shadows-0-0-8 workHours-editor-dataTable">
          <TimeTable
            onRowClick={(value) => setRowId(value)}
            clickedRow={rowId}
            list={list}
          />
        </div>
        {/* <ButtonApp
          title="Vidi vise"
          icon={icons.arrow_right}
          className="see-more"
        /> */}
      </div>
    </div>
  );
};

export default WorkHoursEditor;
