import React, { useState } from 'react';
import './styles/SwitchButtonsOnOff.scss'

function SwitchButtonOnOff() {
  // Koristimo useState hook za praćenje stanja prekidača
  const [isOn, setIsOn] = useState(true); // Difoltno postavljamo na true

  // Funkcija za uključivanje prekidača
  const turnOn = () => {
    setIsOn(true);
  };

  // Funkcija za isključivanje prekidača
  const turnOff = () => {
    setIsOn(false);
  };

  return (
    <div className='on-off-buttons-selector'>
        <div className={isOn ? 'on-off-button on-off-button-active' : 'on-off-button'} onClick={turnOn}>
            <p>On</p>
        </div>
      <div className={!isOn ? 'on-off-button on-off-button-active' : 'on-off-button'} onClick={turnOff}>
        <p>Off</p>
        </div>
    </div>
  );
}

export default SwitchButtonOnOff;