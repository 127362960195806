import React, { useEffect, useState } from "react";
import RemainingDaysProgressBar from "../../../components/RemainingDaysProgressBar/RemainingDaysProgressBar";
import "../../../styles/global.scss";
import "../styles/EmployeesPage.css";
import { useTheme } from "@mui/material";

import { useGetEmploymentInfoQuery } from "../../../store/apiSlice";
import Loader from "../../../components/Loader/Loader";
import ToastError from "../../../components/ToastError/ToastError";
import { useSelector } from "react-redux";

const EmployeesPageHeader = () => {
  const theme = useTheme();
  const [employmentData, setEmploymentData] = useState({});
  const backgroundDefault = theme.palette.background.default;

  const companyData = useSelector((state) => state.company.data);
  const companyId = companyData?.companyId || null;

  const { data, error, isLoading } = useGetEmploymentInfoQuery(
    { id: companyId },
    {
      skip: !companyId,
    }
  );
  useEffect(() => {
    if (data) {
      const { employment } = data?.data;
      if (employment) {
        setEmploymentData(employment);
      }
    }
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    console.error("Failed to fetch data:", error);
    return <ToastError message={"Došlo je do greške. Pokušajte ponovo!"} />;
  }

  return (
    <div
      className="flex-direction-column gap-24 width-100 employees-page-header"
      style={{ backgroundColor: backgroundDefault }}
    >
      <div className="flex-direction-row employees-header-content">
        <div className="flex-direction-row justify-center align-center gap-8 flex-1-0-0 ">
          <p className="Grotesk-500 font-32 employees-header-number">
            {employmentData.total}
          </p>
          <p className="Grotesk-500 font-14 employees-header-text">
            Ukupno zaposlenih
          </p>
        </div>
        <div className="flex-direction-row justify-center align-center gap-8 flex-1-0-0">
          <p className="Grotesk-500 font-32 employees-header-number">
            {employmentData.permanent}
          </p>
          <p className="Grotesk-500 font-14 employees-header-text">
            Zaposleni na odredjeno
          </p>
        </div>
        <div className="flex-direction-row justify-center align-center gap-8 flex-1-0-0">
          <p className="Grotesk-500 font-32 employees-header-number">
            {employmentData.fixed}
          </p>
          <p className="Grotesk-500 font-14 employees-header-text">
            Zaposleni na neoredjeno
          </p>
        </div>
      </div>
      <RemainingDaysProgressBar
        total={employmentData.total}
        left={employmentData.permanent}
        className="employees-header-progress-bar"
      />
    </div>
  );
};

export default EmployeesPageHeader;
