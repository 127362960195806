import React, { useState } from "react";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import InputApp from "../../../components/InputApp/InputApp";
import { icons } from "../../../constants";
import TeamHierarchy from "./TeamHierarchy";
import Documents from "./Documents";
import OtherInfo from "./OtherInfo";
import TrainingCard from "./TrainingCard";
import { useTheme } from "@mui/material";

const ProfileDetails = () => {
  const [activeButton, setActiveButton] = useState(0);

  const handleButtonClick = (title) => {
    setActiveButton(title);
  };

  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  return (
    <>
      <div
        className="flex-direction-column align-start gap-32 shadows width-100 profile-details"
        style={{ backgroundColor: backgroundDefault }}
      >
        <div className="flex-direction-row justify-space-between align-center width-100 profile-details-btn-search">
          <div className="flex-direction-row align-center gap-8 profile-details-buttons">
            <ButtonApp
              title="Tim"
              lightPurple
              active={activeButton === 0}
              onButtonClick={() => handleButtonClick(0)}
              className="profile-page-btn"
            />
            <ButtonApp
              title="Dokumenta"
              lightPurple
              active={activeButton === 1}
              onButtonClick={() => handleButtonClick(1)}
              className="profile-page-btn"
            />
            <ButtonApp
              title="Ostalo"
              lightPurple
              active={activeButton === 2}
              onButtonClick={() => handleButtonClick(2)}
              className="profile-page-btn"
            />
          </div>
          <InputApp
            className="profile-page-search"
            placeholder="search"
            firstIcon={icons.search}
            firstIconReplacement={icons.searchPurple}
          />
        </div>
        {activeButton === 0 && <TeamHierarchy />}
        {activeButton === 1 && <Documents />}
        {activeButton === 2 && <OtherInfo />}
      </div>
      {activeButton === 2 && <TrainingCard />}
    </>
  );
};

export default ProfileDetails;
