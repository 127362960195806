"use client";

import { useRef, useState, useEffect } from "react";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "./setCanvasPreview";
import { Image } from "@mui/icons-material";
import { icons } from "../../constants";
import ButtonApp from "../ButtonsApp/ButtonRegular";

const ASPECT_RATIO = 100 / 100;
const MIN_DIMENSION = 100;

const ImageCropper = ({ imgSrc, onCropComplete, imgName, handleXClick }) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);

  useEffect(() => {
    if (!completedCrop || !imgRef.current || !previewCanvasRef.current) {
      return;
    }

    setCanvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      convertToPixelCrop(
        completedCrop,
        imgRef.current.width,
        imgRef.current.height
      )
    );
  }, [completedCrop]);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleCropImage = () => {
    previewCanvasRef.current.toBlob((blob) => {
      const file = new File([blob], imgName, {
        type: "image/png",
        lastModified: new Date().getTime(),
      });
      onCropComplete(file);
    }, "image/png");
  };
  const onXClick = () => {
    handleXClick();
  };
  return (
    <div className="flex-direction-row align-center justify-center items-center image-cropper">
      {imgSrc && (
        <div className="flex-direction-column align-center gap-12 relative">
          <Image
            src={icons.x_icon}
            alt="move down"
            width={20}
            height={20}
            className="cropper-x"
            onClick={onXClick}
          />
          <ReactCrop
            crop={crop}
            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            circularCrop
            keepSelection
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              style={{ maxHeight: "70vh", maxWidth: "95vw" }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <ButtonApp
            title={"Crop Image"}
            padding={"14px"}
            className="crop-button"
            onButtonClick={handleCropImage}
            purple
          />
        </div>
      )}
      {crop && (
        <canvas
          ref={previewCanvasRef}
          className="mt-4"
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: 150,
            height: 150,
          }}
        />
      )}
    </div>
  );
};

export default ImageCropper;
