import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../styles/styles.css";
import ImageCropper from "../ImageCropper";
import { icons } from "../../../constants";

const ImageDropzone = ({ onImageDrop, image }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [imgName, setImgName] = useState(null);
  const [localImage, setLocalImage] = useState(image); // Promenio naziv lokalnog stanja

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImgSrc(reader.result);
      setImgName(file.name);
    };
    reader.readAsDataURL(file);
  }, []);

  const handleCropComplete = (croppedImage) => {
    onImageDrop(croppedImage);
    setImgSrc(null); // Zatvara cropper nakon seckanja
    setImgName(null);
    setLocalImage(croppedImage); // Postavlja lokalno stanje
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="flex-direction-row items-center width-100 pointer drop-zone-container">
      {localImage ? ( // Koristi lokalno stanje umesto prop-a
        <div className="width-100 drop-zone-image-preview">
          <img src={URL.createObjectURL(localImage)} alt="preview" />
        </div>
      ) : imgSrc ? (
        <ImageCropper
          imgSrc={imgSrc}
          imgName={imgName}
          onCropComplete={handleCropComplete}
          handleXClick={() => {
            setImgSrc(null);

            setImgName(null);
            setLocalImage(null);
          }} // Ispravi da koristi lokalno stanje
        />
      ) : (
        <div
          {...getRootProps()}
          className="flex-direction-row justify-center align-center width-100 drop-zone"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="flex-direction-row justify-center width-100">
              Drop the file here ...
            </p>
          ) : (
            <img src={icons.add_image} alt="Add image" width={60} height={60} />
          )}
        </div>
      )}
    </div>
  );
};

export default ImageDropzone;
