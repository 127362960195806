import React, { useState, useEffect } from "react";
import "./styles/styles.scss";

const ToastError = ({ message }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return <div className={`toast ${show ? "show" : ""}`}>{message}</div>;
};

export default ToastError;
