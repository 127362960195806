import React from "react";
import "../../../styles/attendancePage.scss";
import { useTheme } from "@mui/material";
import ButtonApp from "../../../../../components/ButtonsApp/ButtonRegular";

const Buttons = (props) => {
  const activeButton = props.activeButton;
  const theme = useTheme();
  const moreLightest = theme.palette.primary.moreLightest;
  const neutralMedium = theme.palette.neutral.medium;
  const backgroundAlt = theme.palette.background.alt;

  const handleButtonClick = (buttonId) => {
    props.receiveButtonId(buttonId);
  };
  return (
    <div
      className="flex-direction-row justify-space-between align-center align-stretch attendance-content-buttons"
      style={{ backgroundColor: moreLightest }}
    >
      <ButtonApp
        title="Dan"
        className={`attendance-btn ${activeButton === "day" ? "active" : ""}`}
        textColor={neutralMedium}
        backgroundColor={activeButton === "day" ? backgroundAlt : "transparent"}
        onButtonClick={() => handleButtonClick("day")}
      />
      <ButtonApp
        title="Zaposleno lice"
        className={`attendance-btn ${
          activeButton === "employee" ? "active" : ""
        }`}
        textColor={neutralMedium}
        backgroundColor={
          activeButton === "employee" ? backgroundAlt : "transparent"
        }
        onButtonClick={() => handleButtonClick("employee")}
      />
      <ButtonApp
        title="Kumulativni prikaz"
        className={`attendance-btn ${
          activeButton === "cumulative" ? "active" : ""
        }`}
        textColor={neutralMedium}
        backgroundColor={
          activeButton === "cumulative" ? backgroundAlt : "transparent"
        }
        onButtonClick={() => handleButtonClick("cumulative")}
      />
    </div>
  );
};
export default Buttons;
