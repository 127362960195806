import React, { useEffect, useState } from "react";
import "../../styles/attendancePage.scss";
import { useTheme } from "@mui/material";
import Buttons from "./components/Buttons";
import FilterPanel from "./components/FilterPanel";
import WorkHoursTracker from "./components/WorkHoursTracker";
import EmployeePerson from "./components/EmployeePerson";
import CumulativeView from "./components/CumulativeView";

const AttendanceContent = (props) => {
  const [date, setDate] = useState();
  const [search, setSearch] = useState("");
  const [enterSearch, setEnterSearch] = useState("");
  const activeButton = props.activeContentButton;

  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;

  const changeClickedButton = (buttonId) => {
    props.receiveButtonId(buttonId);
  };
  useEffect(() => {
    console.log("date", date);
    console.log("search", search);
  }, [date, search]);
  return (
    <div
      className="flex-direction-column align-center gap-24 align-stretch shadows-2-2-8 attendance-content"
      style={{ backgroundColor: backgroundDefault }}
    >
      <Buttons
        receiveButtonId={(buttonId) => changeClickedButton(buttonId)}
        activeButton={activeButton}
      />
      <FilterPanel
        setDate={(value) => setDate(value)}
        date={date}
        search={search}
        setSearch={setSearch}
        setEnterSearch={setEnterSearch}
      />
      {activeButton === "day" && (
        <WorkHoursTracker
          status={props.status}
          date={date}
          search={search}
          activeButton={activeButton}
          enterSearch={enterSearch}
        />
      )}
      {/* {activeButton === "employee" && <EmployeePerson />}
      {activeButton === "cumulative" && <CumulativeView />} */}
    </div>
  );
};

export default AttendanceContent;
