import React, {useState} from "react";
import "../styles/settingspage.scss"


const LanguageSelector = ({ languages, title }) => {
    const [activeLanguge, setActiveLanguage] = useState(0);

    const activateLanguge = (index) => {
        setActiveLanguage(index);
      };
    
      return (
        <div className="settings-language-selector">
            <div className="language-selector-title">
                {title}
            </div>
            <div className="languages-conteiner">
                {languages.map((language, index) => (
                    <div
                    key={index}
                    className={`clickable ${index === activeLanguge ? 'active-language language' : 'language'}`}
                    onClick={() => activateLanguge(index)}
                    >
                    {language}
                    </div>
                ))}
            </div>

        </div>
      );

}

export default LanguageSelector;