import React, { useState, useEffect } from "react";
import "./styles/homepage.scss";
import TimeButton from "./components/TimeButton";
import TimeTable from "./components/TimeTable";
import HeaderTimeLine from "./components/HeaderTimeLine";
import DailyGraph from "./components/DailyGraph";
import AvgGraph from "./components/AvgGraph";
import Notifications from "./components/Notifications";
import { useTheme } from "@mui/material";

import {
  useGetEmployeeHomeQuery,
  useCreateWebLogMutation,
} from "../../store/apiSlice";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import ToastError from "../../components/ToastError/ToastError";
import { useConfirmation } from "../../components/ConfirmationModal/ConfirmationModal";
import filterAndSortDailyLogs from "../../utils/index";

const HomePage = () => {
  const list = [];
  const [rowId, setRowId] = useState();
  const [logs, setLogs] = useState([]);
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const neutralMedium = theme.palette.neutral.medium;

  const userId = useSelector((state) => state.user.data.userId);
  const { data, error, isLoading } = useGetEmployeeHomeQuery({ id: userId });
  const [
    createWebLog,
    { isLoading: isCreating, error: createError, data: createResponse },
  ] = useCreateWebLogMutation();

  useEffect(() => {
    if (data) {
      setLogs(data?.data?.employee?.dailyLogs);
    }
  }, [data]);

  const [confirm, ConfirmationModalComponent] = useConfirmation();

  const handleCreateWebLog = async (type) => {
    const message = `Da li ste sigurni?`;
    const result = await confirm(message);
    if (!result) {
      return;
    }
    try {
      await createWebLog({
        input: { employeeId: userId, type: type },
      }).unwrap();
    } catch (err) {
      console.error("Failed to create web log:", err);
    }
  };

  if (isLoading || isCreating) {
    return <Loader />;
  }
  if (error || createError) {
    let err = error || createError;
    console.error("Failed to fetch data:", err);
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  if (data.errors || createResponse?.errors) {
    let err = data.errors || createResponse.errors;
    console.error("Failed to fetch data:", err[0]);
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  // const { dailyLogs } = data?.data?.employee;
  const dailyLogs = logs;
  const filteredLogs = filterAndSortDailyLogs(dailyLogs, list);

  console.log(dailyLogs);
  return (
    <div className="homepage-main">
      <div className="div-white-r24-shadows">
        <HeaderTimeLine data={filteredLogs} />
      </div>
      <div className="time-components">
        <div
          className="time-buttons div-white-r24-shadows"
          style={{ backgroundColor: backgroundDefault }}
        >
          <TimeButton
            title="Dolazak"
            color="var(--Green)"
            onClick={() => handleCreateWebLog("CHECK_IN")}
          />
          <TimeButton
            title="Odlazak"
            color="var(--Red)"
            onClick={() => handleCreateWebLog("CHECK_OUT")}
          />
          <TimeButton
            title="Pauza"
            color="var(--blue)"
            onClick={() => handleCreateWebLog("BREAK")}
          />
          <TimeButton
            title="Rad od kuce"
            color="var(--Yellow)"
            onClick={() => handleCreateWebLog("WORK_FROM_HOME")}
          />
          <TimeButton
            title="Privatan izlazak"
            color="var(--Purple-5)"
            onClick={() => handleCreateWebLog("PRIVATE")}
          />
          <TimeButton
            title="Sluzbeni izlazak"
            color="var(--Purple-2)"
            onClick={() => handleCreateWebLog("BUSINESS")}
          />
        </div>
        <div
          className="time-table div-white-r24-shadows"
          style={{ backgroundColor: backgroundDefault }}
        >
          <TimeTable
            onRowClick={(value) => setRowId(value)}
            clickedRow={rowId}
            list={list}
          />
        </div>
        {ConfirmationModalComponent}
      </div>
      <div
        className="div-white-r24-shadows daily-graph"
        style={{ backgroundColor: backgroundDefault, color: neutralMedium }}
      >
        <div className="daily-graph-header">
          <p className="Grotesk-500 font-16">Dnevna vremenska osa</p>
          <div className="daily-graph-header-legend">
            <div
              className="daily-graph-header-legend"
              style={{
                gap: "6px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#883CEF",
                }}
              ></div>
              <p className="Grotesk-400 font-16">vreme rada</p>
            </div>
            <div
              className="daily-graph-header-legend"
              style={{
                gap: "6px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#5707B1",
                }}
              ></div>
              <p className="Grotesk-400 font-16">vreme pauze</p>
            </div>
          </div>
        </div>
        <DailyGraph list={dailyLogs} today={true} />
      </div>
      {/* <div className="time-components">
        <div
          className="avg-graph div-white-r24-shadows"
          style={{ backgroundColor: backgroundDefault }}
        >
          <div className="avg-graph-header">
            <p className="Grotesk-500 font-16">Prosečno vreme dolaska</p>
            <p className="p-16-black-700" style={{ fontSize: "32px" }}>
              7:32
            </p>
          </div>

           <AvgGraph /> 
        </div>
         <Notifications /> 
      </div> */}
    </div>
  );
};
export default HomePage;
