import React, {useState} from "react";
import "../styles/settingspage.scss"
import SwitchButtonOnOff from "../../../components/SwitchButtonsOnOff/SwitchButtonsOnOff";

const NotificationsSelector = ({ notifications }) => {
    return (
        <div className="settings-notifications-selector">
            {notifications.map((notification, index) => (
                <div key={index} className="notification">
                    <p>{notification}</p>
                    <SwitchButtonOnOff />
                </div>
            ))

            }
        </div>
      );
}

export default NotificationsSelector;