import React, { useState } from "react";
import "./styles/settingspage.scss"
import { icons } from "../../constants";
import LanguageSelector from "./components/langugageSelector";
import NotificationsSelector from "./components/NotificationsSelector";
import ThemeSelector from "./components/ThemeSelector";
import PasswordChangeForm from "./components/PasswordChangeForm";



const SettingsPage = () =>{

    const languages = ['Srpski', 'English', 'German'];
    const notifications = ['Notifikacije', 'Email notifikacije', 'Mobilne notifikacije', 'Notifkacije upozorenja za kašnjenje'];
    const [passwordChanged, setPasswordChanged] = useState(false);

    const passwordChangedHandler = (props) =>{
        setPasswordChanged(props);
    }

    return (
        <div className="settingspage-main div-white-r24-shadows">
            <div className="settings-user-preference-selector">
                <LanguageSelector languages={languages} title={"Izbor jezika"}/>
                <NotificationsSelector notifications={notifications}/>
                <ThemeSelector />
            </div>

            <div className="settings-password-change-section">
                <p>Promena sifre</p>
                <PasswordChangeForm onPasswordChanged={passwordChangedHandler}/>
                {passwordChanged && (
                    <div className="password-changed-successfully-background">
                        <div className="password-changed-successfully">
                            <img src={icons.successfully} onClick={() => passwordChangedHandler(false)}></img>
                            <p>Uspešno ste promenili šifru!</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SettingsPage;