import React, { useState, useEffect } from "react";
import "../../../styles/attendancePage.scss";
import "../../../../../styles/global.scss";
import ButtonApp from "../../../../../components/ButtonsApp/ButtonRegular";
import Dropdown from "../../../../../components/Dropdown/Dropdown";
import InputApp from "../../../../../components/InputApp/InputApp";
import CalendarInput from "../../../../../components/Calendar/Calendar";

import {
  useCreateLogMutation,
  useUpdateLogMutation,
} from "../../../../../store/apiSlice";
import { useConfirmation } from "../../../../../components/ConfirmationModal/ConfirmationModal";
import Loader from "../../../../../components/Loader/Loader";
import ToastError from "../../../../../components/ToastError/ToastError";

const AddActionModal = ({
  handleCloseClick,
  data,
  editing,
  id,
  employeeId,
  date,
}) => {
  const [actionObject, setActionObject] = useState(
    data
      ? {
          type: options.find((option) => option.name === data.type),
          time: data.time,
          date: date ? date : new Date().toISOString().split("T")[0],
        }
      : {
          type: "",
          time: "",
          date: "",
          comment: "",
        }
  );

  const setInputValue = (key, value) => {
    setActionObject((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };
  const [errors, setErrors] = useState({});
  const [confirm, ConfirmationModalComponent] = useConfirmation();
  const [
    createLog,
    { isLoading: isCreating, error: createError, data: createData },
  ] = useCreateLogMutation();
  const [
    updateLog,
    { isLoading: isUpdating, error: updateError, data: updateData },
  ] = useUpdateLogMutation();

  const validateAllFields = () => {
    let hasError = false;

    setErrors({});

    Object.entries(actionObject).forEach(([key, value]) => {
      let err = false;

      if (!value && key !== "comment") {
        err = true;
      }

      if (err) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: true,
        }));
        hasError = true;
      }
    });

    return !hasError;
  };

  const onSaveClick = () => {
    const isValid = validateAllFields();

    console.log(errors);

    if (!isValid) {
      return;
    }
    //creating time string for backend
    const dateTimeString = `${actionObject.date}T${actionObject.time}:00`;
    let dateObject = new Date(dateTimeString);
    let time = dateObject.toISOString();

    //creating input object for backend
    let input = {
      type: actionObject.type.value,
      time: time,
      comment: actionObject.comment,
    };
    if (editing) {
      console.log("editujemo", actionObject);

      input.id = id;
      handleUpdateLog(input);
    } else {
      console.log("dodajemo", actionObject);

      // if we are adding action to specific employee we need to add employeeId to input object
      input.employeeId = employeeId;
      handleCreateLog(input);
    }
  };
  const handleCreateLog = async (input) => {
    const message = `Da li ste sigurni?`;
    const result = await confirm(message);
    if (!result) {
      return;
    }
    createLog({ input: input });
  };
  const handleUpdateLog = async (input) => {
    const message = `Da li ste sigurni?`;
    const result = await confirm(message);
    if (!result) {
      return;
    }
    updateLog({ input: input });
  };
  if (isCreating || isUpdating) {
    return <Loader />;
  }
  if (createError || updateError) {
    console.error("Failed to fetch data:", createError || updateError);
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  if (createData?.errors || updateData?.errors) {
    console.error(
      "Failed to fetch data:",
      createData?.errors || updateData?.errors
    );
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }

  return (
    <div className="flex-direction-column gap-24 align-start align-stretch add-action-modal">
      <div className="flex-direction-column gap-8 justify-space-between height-100 align-start align-stretch add-action-modal-content">
        {ConfirmationModalComponent}

        <Dropdown
          options={options}
          label="Tip akcije"
          setSelectedOption={(option) => setInputValue("type", option)}
          selectedOption={actionObject.type}
          error={errors.type}
        />
        <InputApp
          label="Vreme"
          placeholder="12:22"
          className="add-action-modal-time"
          onChange={(value) => setInputValue("time", value)}
          value={actionObject.time}
          type="time"
          error={errors.time}
        />
        {/* <InputApp
          label="Datum"
          className="add-action-modal-time"
          onChange={(value) => setInputValue("date", value)}
          value={actionObject.date}
          type="date"
          error={errors.date}
        /> */}
        <CalendarInput
          onDateChange={(value) => setInputValue("date", value)}
          value={actionObject.date}
          error={errors.date}
          label="Datum"
        />
        <InputApp
          label="Komentar"
          className="add-action-modal-textarea"
          onChange={(value) => setInputValue("comment", value)}
          value={actionObject.comment}
          error={errors.coment}
          textarea
        />
      </div>
      <div className="flex-direction-row gap-10 align-center width-100 modal-buttons">
        <ButtonApp
          title="Otkazi"
          width="100%"
          onButtonClick={() => handleCloseClick()}
        />
        <ButtonApp
          purple
          title="Sacuvaj"
          width="100%"
          onButtonClick={onSaveClick}
        />
      </div>
    </div>
  );
};

export default AddActionModal;

const options = [
  { id: "1", name: "Dolazak", value: "CHECK_IN" },
  { id: "2", name: "Odlazak", value: "CHECK_OUT" },
  { id: "3", name: "Pauza", value: "BREAK" },
  { id: "4", name: "Rad od kuce", value: "WORK_FROM_HOME" },
  { id: "5", name: "Privatan izlazak", value: "PRIVATE" },
  { id: "6", name: "Sluzbeni izlazak", value: "BUSINESS" },
];
