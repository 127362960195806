import React from "react";
import "../../styles/global.scss";
import "./styles/homepageAdmin.scss";
import "./styles/homepage.scss";
import HeaderStatistic from "./components/HeaderStatistic";
import Notifications from "./components/Notifications";
import MyAbsences from "./components/MyAbsences";
import ExpiringContracts from "./components/ExpiringContracts";
import AvgGraph from "./components/AvgGraph";
import { useTheme } from "@mui/material";

const HomePageAdmin = () => {
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  return (
    <div className="flex direction-column gap-24 homepage-main">
      <div className="flex-direction-row gap-24 homepage-content">
        <div className="flex-direction-column gap-24 width-100 contracts-graphic">
          <HeaderStatistic />
          <div
            className="width-100 avg-graph-admin"
            style={{ backgroundColor: backgroundDefault }}
          >
            <div className="avg-graph-header">
              <p className="Grotesk-500 font-16">Prosečno vreme dolaska</p>
              {/* <p className="p-16-black-700" style={{ fontSize: "32px" }}>
                7:32
              </p> */}
            </div>
            {/* <AvgGraph /> */}
          </div>

          <ExpiringContracts />
        </div>
        <div className="flex-direction-column gap-24 width-100 notification-requests">
          <MyAbsences />
          <Notifications />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default HomePageAdmin;
