import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./styles/styles.scss";
import "../../styles/global.scss";

const ConfirmationModal = ({ message, onClose }) => {
  const handleYes = () => {
    onClose(true);
  };

  const handleNo = () => {
    onClose(false);
  };

  return ReactDOM.createPortal(
    <div className="confirmation-modal">
      <div className="confirmation-modal-content">
        <p>{message}</p>
        <div className="confirmation-modal-buttons">
          <button className="confirmation-modal-yes" onClick={handleYes}>
            Da
          </button>
          <button className="confirmation-modal-no" onClick={handleNo}>
            Ne
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export const useConfirmation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [resolver, setResolver] = useState(null);

  const confirm = (message) => {
    setMessage(message);
    setIsOpen(true);
    return new Promise((resolve) => {
      setResolver(() => resolve);
    });
  };

  const handleClose = (result) => {
    setIsOpen(false);
    if (resolver) {
      resolver(result);
    }
  };

  const ConfirmationModalComponent = isOpen ? (
    <ConfirmationModal message={message} onClose={handleClose} />
  ) : null;

  return [confirm, ConfirmationModalComponent];
};

// Usage in another component
// const [confirm, ConfirmationModalComponent] = useConfirmation();
// const handleAction = async () => {
//   const result = await confirm('Are you sure?');
//   if (result) {
//     // Perform the action
//   }
// };
// return (
//   <div>
//     <button onClick={handleAction}>Do something</button>
//     {ConfirmationModalComponent}
//   </div>
// );
