import React, { useState } from "react";
import "../styles/homepage.scss";
import { icons } from "../../../constants";
import { useTheme } from "@mui/material";

const timeLines = {
  dolazak: "/",
  odlazak: "/",
  pauza: "/",
  total: "/",
};

const formatTime = (time) => {
  if (time) {
    const date = new Date(time);
    return `${date.getHours()}:${date.getMinutes()}`;
  }
  return "/";
};
const calculateTime = (data) => {
  if (!data.checkIn || !data.checkOut) return "/";
  const checkIn = new Date(data.checkIn);
  const checkOut = new Date(data.checkOut);
  const diff = checkOut - checkIn;
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const time = `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}`;
  return time;
};

const HeaderTimeLine = ({ data }) => {
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const neutralMedium = theme.palette.neutral.medium;
  if (data.length > 0) {
    timeLines.dolazak = formatTime(
      data.find((item) => item.type === "CHECK_IN")?.time
    );
    timeLines.odlazak = formatTime(
      data.find((item) => item.type === "CHECK_OUT")?.time
    );
    timeLines.pauza = formatTime(
      data.find((item) => item.type === "BREAK")?.time
    );
    timeLines.total = calculateTime({
      checkIn: data.find((item) => item.type === "CHECK_IN")?.time,
      checkOut: data.find((item) => item.type === "CHECK_OUT")?.time,
    });
  }
  return (
    <div
      className="header-component"
      style={{ backgroundColor: backgroundDefault, borderRadius: "24px" }}
    >
      <div className="header-items">
        <img src={icons.in_icon} />
        <div className="header-text">
          <p
            className="Grotesk-500 home-header-term"
            style={{ color: neutralMedium }}
          >
            Dolazak
          </p>
          <p className="Grotesk-400 home-header-value">{timeLines.dolazak}</p>
        </div>
      </div>
      <div className="header-items">
        <img src={icons.out} />
        <div className="header-text">
          <p
            className="Grotesk-500 home-header-term"
            style={{ color: neutralMedium }}
          >
            Odlazak
          </p>
          <p className="Grotesk-400 home-header-value">{timeLines.odlazak}</p>
        </div>
      </div>
      <div className="header-items">
        <img src={icons.pause} />
        <div className="header-text">
          <p
            className="Grotesk-500 home-header-term"
            style={{ color: neutralMedium }}
          >
            Pauza
          </p>
          <p className="Grotesk-400 home-header-value">{timeLines.pauza}</p>
        </div>
      </div>
      <div className="header-items">
        <img src={icons.clockBlack} fill="red" />
        <div className="header-text">
          <p
            className="Grotesk-500 home-header-term"
            style={{ color: neutralMedium }}
          >
            Ukupno
          </p>
          <p className="Grotesk-400 home-header-value">{timeLines.total}</p>
        </div>
      </div>
    </div>
  );
};
export default HeaderTimeLine;
