import React, { useState, useEffect } from "react";
import "./styles/navbarMobile.scss";
import { useTheme } from "@mui/material";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { images } from "../../constants";
import { CustomLink } from "../Navbar/Navbar";
import { icons } from "../../constants";
import "../../styles/global.scss";

import { useSelector } from "react-redux";
import {
  useGetCompanyIdByUserIdQuery,
  useGetCompanyInfoQuery,
} from "../../store/apiSlice";
import Loader from "../../components/Loader/Loader";
import ToastError from "../../components/ToastError/ToastError";
import { roles } from "../../constants";
import UserService from "../../services/userService.js";
import { useDispatch } from "react-redux";
import { clearUser } from "../../store/userSlice.js";
import { setCompany } from "../../store/companySlice.js";

const NavbarMobile = (props) => {
  const isAdmin = UserService.hasRole([roles.company_admin, roles.super_admin]);
  const userId = useSelector((state) => state.user.data.userId);
  const [photo, setPhoto] = useState(images.profilePicture);
  const dispatch = useDispatch();

  const {
    data: userIdData,
    isLoading: isUserIdLoading,
    error: userIdError,
  } = useGetCompanyIdByUserIdQuery({ id: userId });
  const companyId = userIdData?.data?.employee?.companyId; // Assuming the response contains a `companyId` field.
  useEffect(() => {
    if (userIdData?.data?.employee?.photo) {
      setPhoto(userIdData.data.employee.photo);
    }
    dispatch(setCompany({ companyId }));
  }, [userIdData]);

  const {
    data: companyInfoData,
    isLoading: isCompanyInfoLoading,
    error: companyInfoError,
  } = useGetCompanyInfoQuery(
    { id: companyId },
    {
      skip: !companyId, // Only fetch when companyId is available.
    }
  );
  const [openPresence, setOpenPresence] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const neutralDarker = theme.palette.neutral.darker;

  // const handleLogout = async () => {
  //   setOpenOptions(!openOptions);
  // };
  const handleLogout = async () => {
    UserService.doLogout();
    dispatch(clearUser());
  };

  const { name } = useSelector((state) => state.user.data);
  if (isCompanyInfoLoading || isUserIdLoading) {
    return <Loader />;
  }
  if (companyInfoError || userIdError) {
    console.error(
      "Failed to fetch data:",
      companyInfoError || companyInfoError
    );
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  if (companyInfoData?.data?.errors || userIdData?.data?.errors) {
    console.error(
      "Failed to fetch data:",
      companyInfoData?.data?.errors[0] || userIdData?.data?.errors[0]
    );
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  return (
    <div
      className="flex-direction-column justify-center align-start align-stretch width-100 relative navbar-mobile"
      style={{ backgroundColor: backgroundDefault, color: neutralDarker }}
    >
      <div className="flex-direction-row align-center align-stretch justify-space-between">
        <div
          className="flex-direction-row align-center gap-10 height-100"
          style={{ maxWidth: "calc(100% - 30px)" }}
        >
          <img
            src={photo}
            className="navbar-mobile-avatar-img"
            height="48px"
            width="48px"
          />
          <div
            className="flex-direction-column gap-5 justify-center align-start navbar-mobile-name"
            style={{ padding: "3px 0" }}
          >
            <p className="Grotesk-500 font-16 ellipsis">{name}</p>
            <p className="Grotesk-400 font-16">
              {" "}
              {companyInfoData?.data?.company?.shortName}
            </p>
          </div>
        </div>
        <div className="icon-container relative">
          <MenuIcon
            className={`pointer icon ${
              openOptions ? "icon-hidden" : "icon-visible"
            }`}
            onClick={() => setOpenOptions(!openOptions)}
          />
          <XIcon
            className={`pointer icon ${
              openOptions ? "icon-visible" : "icon-hidden"
            }`}
            onClick={() => setOpenOptions(!openOptions)}
          />
        </div>
      </div>
      <div
        className={`flex-direction-column align-start align-stretch width-100 gap-32 ${
          openOptions ? "navbar-mobile-visible" : "navbar-mobile-invisible"
        }`}
        style={{
          padding: "20px",
          backgroundColor: backgroundDefault,
          color: neutralDarker,
          top: "84px",
          left: "0",
        }}
      >
        <div className="flex-direction-column align-start gap-12 align-stretch">
          <CustomLink
            to="/"
            src={icons.home}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Početna
          </CustomLink>
          <CustomLink
            to="/profile"
            src={icons.profile}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Profil
          </CustomLink>
          {isAdmin && (
            <CustomLink to="/attendance" src={icons.time}>
              Prisustvo
            </CustomLink>
          )}
          {/* <div className="flex-direction-row align-center justify-space-between width-100 relative">
            <CustomLink
              to="/attendance"
              src={icons.time}
              onClick={() => setOpenOptions(!openOptions)}
            >
              Prisustvo
            </CustomLink>
            <img
              src={icons.arrow_down}
              width={34}
              height={23}
              onClick={() => {
                setOpenPresence(!openPresence);
              }}
              className={`pointer presence-arrow ${
                openPresence ? "arrow-hidden" : "arrow-visible"
              }`}
            />
          </div>
          <div
            className={`presence-section ${
              openPresence ? "presence-section-visible" : ""
            }`}
          >
            <CustomLink
              to="/presence"
              onClick={() => setOpenOptions(!openOptions)}
              presenceReview
            >
              Pregled prisustva
            </CustomLink>
          </div> */}
          {isAdmin && (
            <CustomLink
              to="/employees"
              src={icons.employees}
              onClick={() => setOpenOptions(!openOptions)}
            >
              Zaposleni
            </CustomLink>
          )}
          {/* <CustomLink
            to="/requests"
            src={icons.requests}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Zahtevi
          </CustomLink>
          <CustomLink
            to="/notifications"
            src={icons.notifications}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Obaveštenja
          </CustomLink>
          <CustomLink
            to="/settings"
            src={icons.settings}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Podešavanja
          </CustomLink> */}
        </div>
        <div
          className="flex-direction-column align-start gap-14 width-100"
          style={{ padding: "0 15px" }}
        >
          <CustomLink to="/login" src={icons.log_out} onClick={handleLogout}>
            Log out
          </CustomLink>
          {/* <CustomLink
            to="/help"
            src={icons.help}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Pomoć
          </CustomLink>
          <CustomLink
            to="/building"
            src={icons.building}
            onClick={() => setOpenOptions(!openOptions)}
          >
            <div className="flex-direction-row align-center justify-space-between width-100">
              Midmeding Nova
              <img src={icons.arrow_right} width={18} height={16} />
            </div>
          </CustomLink> */}
          <div style={{ height: "24px" }}></div>
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;
