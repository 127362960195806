import React, { useEffect, useState } from "react";
// import {
//   PresentEmployees,
//   checkedOutEmployees,
//   holidaysEmployees,
//   unregisteredEmployees,
// } from "../../../const";
import WorkHoursTrackerRow from "./WorkHoursTrackerRow";
import "../../../styles/attendancePage.scss";

import { useGetAttendancesForAttendancePageQuery } from "../../../../../store/apiSlice";
import Loader from "../../../../../components/Loader/Loader";
import ToastError from "../../../../../components/ToastError/ToastError";
import { useSelector } from "react-redux";

const WorkHoursTracker = ({ status, date, activeButton, enterSearch }) => {
  const companyId = useSelector((state) => state.company?.data?.companyId);
  const [data, setData] = useState(null);
  console.log("enterSearch", enterSearch);
  const {
    data: apiData,
    isLoading,
    error,
  } = useGetAttendancesForAttendancePageQuery(
    {
      variables: {
        companyId: companyId,
        date: date,
        search: enterSearch.trim(),
      },
    },
    {
      skip: !companyId,
    }
  );

  useEffect(() => {
    if (apiData) {
      setData(apiData.data?.employeeLogsPerDay?.content);
    }
  }, [apiData]);

  if (isLoading) return <Loader />;
  if (error) {
    console.error("Failed to fetch data:", error);
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  console.log(data);
  return (
    <div className="flex-direction-column align-center gap-12 align-stretch workHours-tracker">
      {data?.map((employed, index) => (
        <WorkHoursTrackerRow
          employed={employed}
          key={index}
          status={status}
          date={date}
          activeButton={activeButton}
        />
      ))}
    </div>
  );
};

export default WorkHoursTracker;
