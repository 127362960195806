import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import HomePageAdmin from "./HomePage/HomePageAdmin";
import ProfilePage from "./ProfilePage/ProfilePage";
import AttendancePage from "./AttendancePage/AttendancePage";
import SettingsPage from "./SettingsPage/SettingsPage";
import EmployeesPage from "./EmployeesPage/EmployeesPage";
import AddEmployeePage from "./AddEmployeePage/AddEmployeePage";
import HelpPage from "./HelpPage/HelpPage";
import UserService from "../services/userService";
import { roles } from "../constants";

const AppRoutes = () => {
  const isAdmin = UserService.hasRole([roles.company_admin, roles.super_admin]);

  return (
    <Routes>
      <Route path="/" element={!isAdmin ? <HomePage /> : <HomePageAdmin />} />
      <Route path="/profile/:id?" element={<ProfilePage />} />
      <Route path="/attendance" element={<AttendancePage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/employees" element={<EmployeesPage />} />
      <Route path="/employees/add" element={<AddEmployeePage />} />
      <Route path="/help" element={<HelpPage />} />
    </Routes>
  );
};

export default AppRoutes;
