const getEmployeesQuery = `
    query ($companyId: UUID!, $page: Int! = 0, $size: Int! = 10) {
        employees(companyId: $companyId, page: $page, size: $size) {
            content {
                id
                companyId
                cardId
                address
                user {
                  name
                  surname
                  id
                  email
                  phone
                }
            }
        }
    }
`;

const getAttendanceQuery = `
    query ($companyId: UUID!) {
        attendance(companyId: $companyId) {
            currentAbsence {
                change
                value
            }
            currentAttendance {
                change
                value
            }
            totalEmployees {
                change
                value
            }
            weeklyAttendance {
                change
                value
            }
        }
    }
`;

const employeeHomeQuery = `
    query ($id: UUID!) {
        employee(id: $id) {
            companyId
            dailyLogs {
                id
                location
                time
                type
            }
        }
    }`;

const getCompanyIdByUserIdQuery = `
    query ($id: UUID!) {
        employee(id: $id) {
            companyId
            photo
        }
    }`;
const getCompanyInfoQuery = `
    query ($id: UUID!) {
    company(id: $id) {
        address
        email
        id
        name
        phone
        shortName
        slug
        taxId
    }
    }`;
const getProfilePersonalInfoQuery = `
    query ($id: UUID!) {
      employee(id: $id) {
    cardId
    photo
    user {
      phone
      name
        surname
        email
    }
    address
    position {
      name
      description
    }
    sector {
      description
      name
    }
    birthday
    company {
      address
      shortName
    }
  }
}`;

const getPositionsAndSectorsQuery = `
    query ($id: UUID!) {
        positions (companyId: $id) {
            id
            name
            description
        }
        sectors (companyId: $id) {
            id
            name
            description
        }
    }
`;
const getAttendanceForAdminHomeQuery = `
query ($id: UUID!) {
    attendance(companyId: $id) {
        currentAbsence {
            change
            value
        }
        currentAttendance {
            change
            value
        }
        totalEmployees {
            change
            value
        }
        weeklyAttendance {
            change
            value
        }
    }
}`;
const getListOfEmployeesQuery = `
query ($id: UUID!) {
    employees(companyId: $id, size:100) {
        content {
            user {
                email
                name
                surname
                phone
                id
            }
            photo
            position {
                name
            }
        }
    }
}`;
const getEmploymentInfoQuery = `
    query ($id: UUID!) {
        employment(companyId: $id) {
            fixed
            permanent
            total
        }
    }
`;
const getAttendancePageQuery = `
    query ($companyId: UUID!, $size: Int! = 100, $search: String, $date: Date) {
        employeeLogsPerDay(companyId: $companyId, size: $size, search: $search, date: $date) {
            content {
                id
                name
                photo
                surname
                logs {
                    id
                    location
                    time
                    type
                }
            }
        }
    }
`;
export default {
  employeeHomeQuery,
  getCompanyIdByUserIdQuery,
  getEmployeesQuery,
  getAttendanceQuery,
  getCompanyInfoQuery,
  getProfilePersonalInfoQuery,
  getPositionsAndSectorsQuery,
  getAttendanceForAdminHomeQuery,
  getListOfEmployeesQuery,
  getEmploymentInfoQuery,
  getAttendancePageQuery,
};
