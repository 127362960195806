import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import "../../../../../styles/global.scss";

const ApexChart = () => {
  const [state, setState] = useState({
    series: [value],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#f8e1f4",
            strokeWidth: "97%",
            margin: 5,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
              color: "#D7A2D8",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          gradientToColors: ["#D7A2D8"],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
        },
      },
      colors: ["#D7A2D8"],
      labels: ["Average Results"],
    },
  });

  return (
    <div className="width-100 height-100 flex-direction-row align-center justify-center">
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="radialBar"
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;

const value = 78;
