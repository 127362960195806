import add from "../assets/icons/add.svg";
import add_image from "../assets/icons/add-image.svg";
import arrow_down from "../assets/icons/arrow-down.svg";
import arrow_left from "../assets/icons/arrow-left.svg";
import arrow_right from "../assets/icons/arrow-right.svg";
import arrow_up from "../assets/icons/arrow-up.svg";
import attach_file from "../assets/icons/attach-file.svg";
import birthdays from "../assets/icons/birthdays.svg";
import building from "../assets/icons/building.svg";
import calender from "../assets/icons/calender.svg";
import car from "../assets/icons/car.svg";
import check from "../assets/icons/check.svg";
import courses from "../assets/icons/courses.svg";
import disability from "../assets/icons/disability.svg";
import document from "../assets/icons/document.svg";
import download from "../assets/icons/download.svg";
import ellipse from "../assets/icons/ellipse.svg";
import employees from "../assets/icons/employees.svg";
import file from "../assets/icons/file.svg";
import filter from "../assets/icons/filter.svg";
import filter1 from "../assets/icons/filter1.svg";
import filter2 from "../assets/icons/filter2.svg";
import filter3 from "../assets/icons/filter3.svg";
import filter4 from "../assets/icons/filter4.svg";
import filter5 from "../assets/icons/filter5.svg";
import filter6 from "../assets/icons/filter6.svg";
import filter7 from "../assets/icons/filter7.svg";
import filter_down from "../assets/icons/filter-down.svg";
import filter_up from "../assets/icons/filter-up.svg";
import folder from "../assets/icons/folder.svg";
import home from "../assets/icons/home.svg";
import import_file from "../assets/icons/import.svg";
import important from "../assets/icons/important.svg";
import in_icon from "../assets/icons/in.svg";
import leave from "../assets/icons/leave.svg";
import log_out from "../assets/icons/log-out.svg";
import menu from "../assets/icons/menu.svg";
import moon from "../assets/icons/moon.svg";
import more from "../assets/icons/more.svg";
import not_available from "../assets/icons/not-available.svg";
import notifications from "../assets/icons/notifications.svg";
import out from "../assets/icons/out.svg";
import pause from "../assets/icons/pause.svg";
import pencil from "../assets/icons/pencil.svg";
import position from "../assets/icons/position.svg";
import profile from "../assets/icons/profile.svg";
import requests from "../assets/icons/requests.svg";
import saint_day from "../assets/icons/saint-day.svg";
import search from "../assets/icons/search.svg";
import settings from "../assets/icons/settings.svg";
import sun from "../assets/icons/sun.svg";
import testimonial from "../assets/icons/testimonial.svg";
import time from "../assets/icons/time.svg";
import help from "../assets/icons/help.svg";
import x from "../assets/icons/x.svg";
import clockBlack from "../assets/icons/clock-black.svg";
import meeting from "../assets/icons/meeting.svg";
import modalX from "../assets/icons/modal-x.svg";
import searchPurple from "../assets/icons/search-purple.svg";
import filePink from "../assets/icons/file-pink.svg";
import baby from "../assets/icons/baby.svg";
import decreaseArrow from "../assets/icons/decrease-arrow.svg";
import increaseArrow from "../assets/icons/increase-arrow.svg";
import errorIcon from "../assets/icons/error-icon.svg";
import holiday from "../assets/icons/holiday.svg";

export default {
  add,
  add_image,
  arrow_down,
  arrow_left,
  arrow_right,
  arrow_up,
  attach_file,
  birthdays,
  building,
  calender,
  car,
  check,
  courses,
  disability,
  document,
  download,
  ellipse,
  employees,
  file,
  filter,
  filter1,
  filter2,
  filter3,
  filter4,
  filter5,
  filter6,
  filter7,
  filter_down,
  filter_up,
  folder,
  home,
  import_file,
  important,
  in_icon,
  leave,
  log_out,
  menu,
  moon,
  more,
  not_available,
  notifications,
  out,
  pause,
  pencil,
  position,
  profile,
  requests,
  saint_day,
  search,
  settings,
  sun,
  testimonial,
  time,
  help,
  x,
  clockBlack,
  meeting,
  modalX,
  searchPurple,
  filePink,
  baby,
  decreaseArrow,
  increaseArrow,
  errorIcon,
  holiday,
};
