import React, { useState } from "react";
import { icons } from "../../constants";
import { useTheme } from "@mui/material";
import "./styles/Dropdown.css";

const Dropdown = ({
  label,
  selectedOption,
  setSelectedOption,
  options,
  className,
  error,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  const defaultBackground = theme.palette.background.default;

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div
      className={`flex-direction-column align-center gap-12 width-100 dropdown ${className}`}
    >
      {label && (
        <label
          className="Grotesk-500 font-16 weight-500 width-100"
          style={{
            color: neutralMedium,
            textAlign: "start",
            lineHeight: "20px",
            backgroundColor: defaultBackground,
          }}
        >
          {label}
        </label>
      )}
      <div
        className={`flex-direction-row align-center gap-8 width-100 dropdown-input pointer ${
          error ? "error" : ""
        }`}
        onClick={toggleDropdown}
      >
        <input
          value={selectedOption?.name || ""}
          disabled
          className="width-100"
          style={{ backgroundColor: "white" }}
        />
        <img
          src={isOpen ? icons.arrow_up : icons.arrow_down}
          alt="arrow-down"
          className="pointer"
          width={18}
          height={7}
        />
      </div>
      {isOpen && (
        <div
          className="flex-direction-column width-100 dropdown-menu"
          style={{
            backgroundColor: defaultBackground,
          }}
        >
          {options?.map((option) => (
            <div
              key={option.id}
              className="Grotesk-500 font-16 weight-500 width-100 pointer dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
