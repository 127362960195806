import React from "react";
import { useTheme } from "@mui/material";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import { icons } from "../../../constants";

const absences = [
  {
    type: "Bolovanje",
    status: "Čekanje",
    start_date: "2023-08-26",
    end_date: "2023-08-24",
  },
  {
    type: "Plaćeno odsustvo",
    status: "Odbijeno",
    start_date: "2023-04-26",
    end_date: "2023-08-29",
  },
  {
    type: "Bolovanje",
    status: "Odobreno",
    start_date: "2023-08-26",
    end_date: "2023-08-29",
  },
];

const Absence = (props) => {
  const theme = useTheme();
  const darker = theme.palette.neutral.darker;
  const neutralLighter = theme.palette.neutral.lighter;
  let colorClass = "";
  if (props.absence.status === "Čekanje") {
    colorClass = "orange-text";
  } else if (props.absence.status === "Odbijeno") {
    colorClass = "red-text";
  } else if (props.absence.status === "Odobreno") {
    colorClass = "green-text";
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const calculateDaysDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const difference = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    return difference;
  };

  return (
    <div className="flex-direction-column align-center align-stretch absence">
      <div className="flex-direction-column gap-14 align-center align-stretch">
        <div className="flex-direction-row justify-space-between align-center align-stretch">
          <p
            className="Grotesk-500 font-16 absence-type"
            style={{ color: darker }}
          >
            {props.absence.type}
          </p>
          <p className={`Grotesk-500 font-14 ${colorClass}`}>
            {props.absence.status}
          </p>
        </div>
        <div className="flex-direction-row align-center gap-12 align-stretch">
          <p
            className="Grotesk-400 font-12 date"
            style={{ color: neutralLighter }}
          >
            {formatDate(props.absence.start_date)}
          </p>
          <div className="line width-100"></div>
          <p
            className="Grotesk-400 font-12 date"
            style={{ color: neutralLighter }}
          >
            {formatDate(props.absence.end_date)}
          </p>
        </div>
      </div>
      <p className="Grotesk-400 font-12 date" style={{ color: darker }}>
        Trajanje:{" "}
        {calculateDaysDifference(
          props.absence.start_date,
          props.absence.end_date
        )}{" "}
        dana
      </p>
    </div>
  );
};

const MyAbsences = () => {
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  return (
    <div
      className="flex-direction-column gap-24 align-stretch my-absence"
      style={{ backgroundColor: backgroundDefault }}
    >
      <p
        className="Grotesk-500 my-absence-title"
        style={{ lineHeight: "18px", width: "100%", fontSize: "14px" }}
      >
        Moja odsustva
      </p>
      {/* <div className="flex-direction-column gap-12 align-stretch align-center width-100">
        {absences.map((absence, index) => (
          <Absence key={index} absence={absence} />
        ))}
      </div>
      <div className="flex-direction-column width-100 align-end">
        <ButtonApp
          title="Vidi više"
          icon={icons.arrow_right}
          width="100%"
          className="my-absence-btn"
        />
      </div> */}
    </div>
  );
};

export default MyAbsences;
