import React, { useState } from "react";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import ButtonFolder from "../../../components/ButtonsApp/ButtonSpecial";
import { documents, documentsButtons, tableHeader } from "../constants";
import ProfilePageRow from "./ProfilePageRow";
import { icons } from "../../../constants";
import Filter from "../../../components/Filter/Filter";
import { useTheme } from "@mui/material";
import ModalApp from "../../../components/ModalApp/ModalApp";

const Documents = () => {
  const [openModal, setOpenModal] = useState(false);
  const now = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(now.getDate() - 10);
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return new Date(year, month - 1, day);
  };
  const recentDocuments = documents.filter(
    (doc) => parseDate(doc.created_date) >= tenDaysAgo
  );
  const olderDocuments = documents.filter(
    (doc) => parseDate(doc.created_date) < tenDaysAgo
  );
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  const neutralLight = theme.palette.neutral.light;

  const handleAddFile = () => {
    setOpenModal(true);
  };
  return (
    <div className="flex-direction-column gap-32 align-start width-100 documents animated">
      <div className="flex-direction-column align-start gap-24 width-100">
        <div className="flex-direction-row justify-space-between align-center align-stretch width-100 superiors-btn">
          <p className="Grotesk-500 font-16" style={{ color: neutralMedium }}>
            Folderi
          </p>
          <ButtonApp title="Dodaj" purple onButtonClick={handleAddFile} />
        </div>
        <div className="flex-direction-row gap-24 justify-center align-center width-100 documents-buttons">
          {documentsButtons.length > 0 && (
            <>
              <div className="flex-direction-row gap-24 justify-space-between align-center width-100 documents-two-buttons">
                {documentsButtons.slice(0, 2).map((button, index) => (
                  <ButtonFolder
                    key={index}
                    folder
                    backgroundColor={button.color}
                    title={button.title}
                    filesNumber={button.files}
                    className="documents-btn"
                  />
                ))}
              </div>
              <div className="flex-direction-row gap-24 justify-space-between align-center width-100 documents-two-buttons">
                {documentsButtons.slice(2, 4).map((button, index) => (
                  <ButtonFolder
                    key={index}
                    folder
                    backgroundColor={button.color}
                    title={button.title}
                    filesNumber={button.files}
                    className="documents-btn"
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex-direction-column align-end gap-32 width-100 documents-container">
        <div className="flex-direction-column gap-24 align-start width-100">
          <div className="flex-direction-row justify-space-between align-center align-stretch">
            <p
              className="Grotesk-500 font-16 profile-title"
              style={{ color: neutralMedium }}
            >
              Nova dokumenta
            </p>
            <Filter />
          </div>
          <div className="flex-direction-row align-center gap-24 table-header width-100">
            {tableHeader.map((item, index) => (
              <p
                className={`Grotesk-500 font-16 ${
                  index === 1 || index === 2 ? "hidden-sm" : ""
                } ${index === 3 ? "hidden-xs" : ""}`}
                key={index}
                style={{ color: neutralLight }}
              >
                {item}
              </p>
            ))}
          </div>
          {recentDocuments.map((item, index) => (
            <ProfilePageRow item={item} key={index} document />
          ))}
        </div>
        <div className="flex-direction-column gap-24 align-start width-100">
          <div className="flex-direction-row justify-space-between align-center align-stretch">
            <p
              className="Grotesk-500 font-16 profile-title"
              style={{ color: neutralMedium }}
            >
              Dodatni dokumenti
            </p>
            <Filter />
          </div>
          {olderDocuments.map((item, index) => (
            <ProfilePageRow item={item} key={index} document />
          ))}
        </div>
        <ButtonApp
          title="Vidi više"
          icon={icons.arrow_right}
          className="team-see-more"
        />
      </div>
      {openModal && (
        <div className="flex-direction-row align-center justify-center add-notification">
          <ModalApp
            title="Add a file"
            handleCloseClick={() => setOpenModal(false)}
            profileFile
            optionsArray={[
              "Radni odnosi",
              "Isplatni listici",
              "Resenja",
              "Cv/Karton obuke",
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default Documents;
