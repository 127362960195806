import React, { useState } from "react";
import "./styles/EmployeesPage.css";
import EmployeesPageHeader from "./components/EmployeesPageHeader";
import EmployeesTable from "./components/EmployeesTable";

const EmployeesPage = () => {
  return (
    <div>
      <div className="flex-direction-column gap-24">
        <EmployeesPageHeader total={100} left={60} title={""} />
        <EmployeesTable />
      </div>
    </div>
  );
};

export default EmployeesPage;
