import React from "react";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import { team } from "../constants";
import ProfilePageRow from "./ProfilePageRow";
import { icons } from "../../../constants";
import { useTheme } from "@mui/material";

const TeamHierarchy = () => {
  const superiorsArray = team.filter((obj) => obj.type === "nadzorni");
  const myTeamArray = team.filter((obj) => obj.type === "vas tim");

  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  return (
    <div className="flex-direction-column gap-32 width-100 team-hierarchy animated">
      <div className="flex-direction-column align-start gap-24">
        <div className="flex-direction-row justify-space-between align-center align-stretch superiors-btn">
          <p
            className="Grotesk-500 font-16 profile-title"
            style={{ color: neutralMedium }}
          >
            Nadzorni
          </p>
          <ButtonApp title="Uredi" purple />
        </div>
        <div className="flex-direction-column gap-8 justify-center align-start width-100">
          {superiorsArray.map((member, index) => (
            <ProfilePageRow key={index} item={member} teamMember />
          ))}
        </div>
      </div>
      <div className="flex-direction-column align-end gap-32 width-100">
        <div className="flex-direction-column gap-24 align-start width-100">
          <p
            className="Grotesk-500 font-16 profile-title"
            style={{ color: neutralMedium }}
          >
            Vas tim
          </p>
          <div className="flex-direction-column gap-8 justify-center align-start width-100">
            {myTeamArray.map((member, index) => (
              <ProfilePageRow key={index} item={member} teamMember />
            ))}
          </div>
        </div>
        <ButtonApp
          title="Vidi više"
          icon={icons.arrow_right}
          className="team-see-more"
        />
      </div>
    </div>
  );
};

export default TeamHierarchy;
