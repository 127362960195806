import React from "react";
import "./styles/RemainingDaysProgressBar.scss";

const RemainingDaysProgressBar = ({ total, left, title, className }) => {
  const inneWidth = (100 * left) / total;
  const inneWidth2 = 100 - inneWidth;

  return (
    <div
      className={`profilpage-personal-information-vacation-days ${className}`}
    >
      {title && <p>{title}</p>}
      <div className="RemainingDaysProgressBarOuter">
        <div
          style={{ width: `${inneWidth}%` }}
          className="RemainingDaysProgressBarInner"
        >
          {left}
        </div>
        <div
          style={{ width: `${inneWidth2}%` }}
          className="RemainingDaysProgressBarInner-second"
        >
          {total - left}
        </div>
      </div>
    </div>
  );
};

export default RemainingDaysProgressBar;
