import React from "react";
import "./styles/modal.scss";
import "../../styles/global.scss";
import { useTheme } from "@mui/material";
import { icons } from "../../constants";
import ButtonApp from "../ButtonsApp/ButtonRegular";
import ModalForm from "./components/ModalForm";

const ModalApp = ({
  title,
  handleCloseClick,
  profileFile,
  optionsArray,
  profileInfo,
}) => {
  const theme = useTheme();
  const defaultBackground = theme.palette.background.default;
  const handleSaveClick = () => {};
  return (
    <div
      className="flex-direction-column gap-20 align-start shadows-0-9-20 modal"
      style={{ backgroundColor: defaultBackground }}
    >
      <div className="flex-direction-row justify-space-between align start width-100">
        <p className="Grotesk-500 font-16">{title}</p>
        <img
          src={icons.modalX}
          alt="x"
          width={24}
          height={24}
          className="pointer"
          onClick={handleCloseClick}
        />
      </div>
      <ModalForm
        profileFile={profileFile}
        profileInfo={profileInfo}
        optionsArray={optionsArray}
      />
      <div className="flex-direction-row gap-10 align-center width-100 modal-buttons">
        <ButtonApp
          title="Discard"
          width="100%"
          onButtonClick={handleCloseClick}
        />
        <ButtonApp
          purple
          title="Save"
          width="100%"
          onButtonClick={handleSaveClick}
        />
      </div>
    </div>
  );
};

export default ModalApp;
