import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { icons } from "../../../constants";
import { useGetAttendaceForAdminHomeQuery } from "../../../store/apiSlice";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import ToastError from "../../../components/ToastError/ToastError";

const HeaderStatistic = () => {
  const theme = useTheme();
  const [presences, setPresences] = useState([]);
  const companyData = useSelector((state) => state.company.data);
  const companyId = companyData?.companyId || null;

  const { data, error, isLoading } = useGetAttendaceForAdminHomeQuery(
    { id: companyId },
    {
      skip: !companyId,
    }
  );

  useEffect(() => {
    if (data) {
      const { attendance } = data?.data;
      if (attendance) {
        setPresences([
          {
            title: "Ukupan broj zaposlenih",
            number: attendance?.totalEmployees?.value || 0,
            change: attendance?.totalEmployees?.change || null,
          },
          {
            title: "Trenutno prisustvo",
            number: attendance?.currentAttendance?.value || 0,
            change: attendance?.currentAttendance?.change || null,
          },
          {
            title: "Broj odsutnih",
            number: attendance?.currentAbsence?.value || 0,
            change: attendance?.currentAbsence?.change || null,
          },
        ]);
      }
    }
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    console.error("Failed to fetch data:", error);
    return <ToastError message={"Došlo je do greške. Pokušajte ponovo!"} />;
  }

  const backgroundDefault = theme.palette.background.default;
  const neutralLighterMedium = theme.palette.neutral.lighterMedium;
  const almostBlack = theme.palette.primary.almostBlack;
  const neutralLight = theme.palette.neutral.light;

  return (
    <div className="flex-direction-row align-start gap-24 header-statistic">
      {presences.map((presence, index) => {
        const iconSrc =
          presence.change != null
            ? presence.change >= 0
              ? icons.increaseArrow
              : icons.decreaseArrow
            : null;
        const displayTextSecond =
          presence.change != null
            ? "u odnosu na prošli mesec"
            : "Nema informacija";

        return (
          <div
            key={index}
            className="flex-direction-column justify-space-between presence"
            style={{ backgroundColor: backgroundDefault }}
          >
            <div className="flex-direction-column gap-10 align-start header-statistic-number">
              <p
                className="Grotesk-500 font-16"
                style={{ color: neutralLighterMedium }}
              >
                {presence.title}
              </p>
              <p className="Grotesk-500 font-32" style={{ color: almostBlack }}>
                {presence.number}
              </p>
            </div>

            <div className="flex-direction-row justify-space-between align-center gap-10 header-statistic-graph">
              {iconSrc && (
                <div className="flex-direction-row gap-4">
                  <img src={iconSrc} alt="icon" />
                  <p
                    className="Grotesk-400 font-14"
                    style={{ color: neutralLight }}
                  >
                    {presence.change}
                  </p>
                </div>
              )}
              <p
                className="Grotesk-400 font-14"
                style={{ color: neutralLight }}
              >
                {displayTextSecond}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HeaderStatistic;
