import React, {useState} from "react";
import "../styles/settingspage.scss"
import { icons } from "../../../constants";

const PasswordChangeInput = (props) => {
    const src = icons.passwordSee;
    const srcDont = icons.passwordDontSee;

    const [showPassword, setshowPassword] = useState(false);


    

    const togglePasswordVisibility = () => {
        setshowPassword(!showPassword);
      };

    return(
        <div className={props.problem ? 'password-change-input input-problem' : 'password-change-input'}>
            <input
                onChange={props.onChange}
                type={showPassword ? 'text' : 'password'}
                placeholder={props.placeholder} 
                
             ></input>
            {showPassword ? (
                <img src={src} onClick={togglePasswordVisibility}></img>
            ):(
                <img src={srcDont} onClick={togglePasswordVisibility}></img>
            )}
            
        </div>
        )
}

export default PasswordChangeInput;