import React, { useState, useEffect } from "react";
import { images } from "../../../constants";

import { useSelector } from "react-redux";
import { useGetProfilePersonalInfoQuery } from "../../../store/apiSlice";
import Loader from "../../../components/Loader/Loader";
import ToastError from "../../../components/ToastError/ToastError";

const PersonalInformation = ({ id }) => {
  const Profile = {
    Id: "/",
    Adresa: "/",
    Datum_Rodjenja: "/",
    Telefon: "/",
    Ime_Kompanije: "/",
    Lokacija: "/",
    Sektor: "/",
    Smena: "/",
  };

  const [image, setImage] = useState(null);

  const [profileData, setProfileData] = useState({
    Id: "/",
    Adresa: "/",
    Datum_Rodjenja: "/",
    Telefon: "/",
    Ime_Kompanije: "/",
    Lokacija: "/",
    Sektor: "/",
    Smena: "/",
    Pozicija: "/",
  });
  const { name, email, userId } = useSelector((state) => state.user.data);
  const effectiveId = id || userId;
  const { data, error, isLoading } = useGetProfilePersonalInfoQuery({
    id: effectiveId,
  });
  useEffect(() => {
    if (data?.data) {
      setProfileData({
        Ime:
          `${data?.data?.employee?.user?.name} ${data?.data?.employee?.user?.surname}` ||
          name ||
          "/",
        Email: data?.data?.employee?.user?.email || email || "/",
        Id: data?.data?.employee?.cardId || "/",
        Adresa: data?.data?.employee?.address || "/",
        Datum_Rodjenja: data?.data?.employee?.birthday || "/",
        Telefon: data?.data?.employee?.user?.phone || "/",
        Ime_Kompanije: data?.data?.employee?.company?.shortName || "/",
        Lokacija: data?.data?.employee?.company?.address || "/",
        Sektor: data?.data?.employee?.sector?.name || "/",
        Smena: data?.data?.employee?.shift?.name || "/",
        Pozicija: data?.data?.employee?.position?.name || "/",
      });
      setImage(data?.data?.employee?.photo);
    }
  }, [data]);
  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    console.error("Failed to fetch data:", error);
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  if (data?.data?.errors) {
    console.error("Failed to fetch data:", data.errors[0]);
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }
  const profileEntries = Object.entries(profileData);
  const firstHalf = profileEntries.slice(2, 6);
  const secondHalf = profileEntries.slice(6, 10);
  return (
    <div className="flex-direction-row align-start gap-49 shadows height-100 personal-information">
      <div className="flex-direction-column gap-24 align-start align-stretch personal-information-picture-name">
        <div className="profile-picture">
          <img
            src={image ? image : images.profilePicture}
            alt="Profile"
            className="profile-page-personal-information-uploaded-picture"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "inherit",
            }}
          />
        </div>
        <div className="flex-direction-column gap-24 align-start align-stretch personal-information-email-name">
          <div className="flex-direction-column align-stretch align-start gap-8">
            <p className="Grotesk-500 white-text font-20 white-text personal-information-name">
              {profileData.Ime}
            </p>
            <p
              className="Grotesk-400 gray-3 font-16 capitalize"
              style={{ color: "#fff" }}
            >
              {profileData.Pozicija}
            </p>
          </div>
          <div className="flex-direction-column align-stretch align-start gap-5">
            <p
              className="Grotesk-400 gray-3 font-16 capitalize"
              style={{ color: "#fff" }}
            >
              Email
            </p>
            <p className="Grotesk-500 white-text font-16 white-text">
              {profileData.Email}
            </p>
          </div>
        </div>
      </div>
      <div className="flex-direction-row flex-start gap-15 align-stretch personal-information-container">
        <div
          className="flex-direction-column gap-24 align-start column"
          style={{ width: "167px" }}
        >
          {firstHalf.map(([key, value]) => (
            <div key={key} className="flex-direction-column gap-5 align-start">
              <p
                className="Grotesk-400 gray-3 font-16  capitalize white-text"
                style={{ color: "#fff" }}
              >
                {key.replace(/_/g, " ")}
              </p>
              <p className="Grotesk-500 white-text font-16  white-text">
                {value}
              </p>
            </div>
          ))}
        </div>
        <div className="line"></div>
        <div
          className="flex-direction-column gap-24 align-start column"
          style={{ width: "195px" }}
        >
          {secondHalf.map(([key, value]) => (
            <div key={key} className="flex-direction-column gap-5 align-start">
              <p
                className="Grotesk-400 gray-3 font-16 "
                style={{ color: "#fff" }}
              >
                {key.replace(/_/g, " ")}
              </p>
              <p className="Grotesk-500 white-text font-16  white-text">
                {value}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
