import React from "react";
import "../styles/homepageAdmin.scss";
import "../../../styles/global.scss";
import { useTheme } from "@mui/material";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import { icons, images } from "../../../constants";
import { format, parseISO, differenceInDays } from "date-fns";

const expiringContracts = [
  {
    img: images.profilePicture,
    name: "Marinko Zdravković",
    position: "Frontend developer",
    working_unit: "SQ",
    date: "2024-07-01",
  },
  {
    img: images.profilePicture,
    name: "Marinko Zdravković",
    position: "Frontend developer",
    working_unit: "MP",
    date: "2024-08-01",
  },
  {
    img: images.profilePicture,
    name: "Marinko Zdravković",
    position: "Frontend developer",
    working_unit: "BA",
    date: "2024-09-01",
  },
  {
    img: images.profilePicture,
    name: "Marinko Zdravković",
    position: "Frontend developer",
    working_unit: "BA",
    date: "2024-07-01",
  },
];

const TableRow = ({ contract }) => {
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;

  const currentDate = new Date();
  const contractEndDate = parseISO(contract.date);
  const daysDifference = differenceInDays(contractEndDate, currentDate);

  let dateClass = "";
  if (daysDifference < 30) {
    dateClass = "red-text";
  } else if (daysDifference < 60) {
    dateClass = "orange-text";
  } else {
    dateClass = "green-text";
  }

  const formattedDate = format(contractEndDate, "dd.MM.yyyy.");

  return (
    <div
      className="flex-direction-row align-center justify-space-between width-100 expiring-contracts-table-row"
      style={{ color: neutralMedium }}
    >
      <div className="flex-direction-row align-center gap-5">
        <img src={contract.img} alt="image" width={26} height={26} />
        <p className="Grotesk-500 font-14">{contract.name}</p>
      </div>
      <div className="flex-direction-row">
        <p className="Grotesk-400 font-14">{contract.position}</p>
      </div>
      <div className="flex-direction-row">
        <p className="Grotesk-400 font-14">{contract.working_unit}</p>
      </div>
      <div className="flex-direction-row">
        <p className={`Grotesk-400 font-14 ${dateClass}`}>{formattedDate}</p>
      </div>
    </div>
  );
};

const ExpiringContracts = () => {
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const littleLighter = theme.palette.primary.littleLighter;
  const neutralMedium = theme.palette.neutral.medium;
  const neutralLighterMedium = theme.palette.neutral.lighterMedium;

  return (
    <div
      className="flex-direction-column gap-24 expiring-contracts"
      style={{ backgroundColor: backgroundDefault }}
    >
      <div className="flex-direction-row align-start align-stretch justify-space-between title-btn">
        <p
          className="Grotesk-500 font-16 expiring-contracts-title"
          style={{ color: neutralLighterMedium }}
        >
          Zaposlenja koja ističu
        </p>
        {/* <ButtonApp
          title="Vidi više"
          icon={icons.arrow_right}
          className="expiring-contracts-see-more"
        /> */}
      </div>
      {/* <div className="flex-direction-column align-start gap-10 align-stretch expiring-contracts-table">
        <div
          className="flex-direction-row align-center table-header width-100"
          style={{ backgroundColor: littleLighter, color: neutralMedium }}
        >
          <p className="Grotesk-400 font-14">Ime</p>
          <p className="Grotesk-400 font-14">Pozicija</p>
          <p className="Grotesk-400 font-14">Radna jedinica</p>
          <p className="Grotesk-400 font-14">Status</p>
        </div>
        <div className="flex-direction-column gap-10 width-100 table-content">
          {expiringContracts.map((contract, index) => (
            <React.Fragment key={index}>
              <TableRow contract={contract} />
              <div className="line"></div>
            </React.Fragment>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default ExpiringContracts;
