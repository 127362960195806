const regexPatterns = {
  e_mail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, // Minimum 8 karaktera, bar jedno slovo i jedan broj
  number: /^\d+$/,
};

export function validateString(input, type) {
  const pattern = regexPatterns[type];
  if (!pattern) {
    throw new Error(`Nevalidan tip regexa: ${type}`);
  }
  return pattern.test(input);
}
