import React,{useState} from "react";
import "../styles/settingspage.scss"
import PasswordChangeInput from "./PasswordChangeInput";

const PasswordChangeForm = (props) => {
    const [passwordsEqual, setPasswordsEqual] = useState(false);
    const [passwordsRegex, setPasswordsRegex] = useState(false);
    const [oldPasswordCorrect, setOldPasswordCorrect] = useState(false);
    const [problem, setProblem] = useState({
        oldPass: false,
        newPass: false,
        newPassRepeated: false
    });

    const [inputValues, setInputValues] = useState({
        oldPass: '',
        newPass: '',
        newPassRepeated: ''
      });
      const handleChange = (e) => {
        const { name, value } = e;
        setInputValues({ ...inputValues, [name]: value });
      };
      const handleSubmit = (e) => {
        const password = inputValues.newPass;
        const passwordRegex = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()-_+={}[\]|\\\\:;\"'<,>.?/])(?=.*[a-zA-Z]).{8,}$";

        if(inputValues.newPass !== inputValues.newPassRepeated){
            setPasswordsEqual(true);
            setProblem({ ["oldPass"]:false, ["newPass"]:true, ["newPassRepeated"]:true });
            setPasswordsRegex(false);
        }else if (!RegExp(passwordRegex).test(password)) {
            setProblem({ ["oldPass"]:false, ["newPass"]:true, ["newPassRepeated"]:false });
            setPasswordsEqual(false);
            setPasswordsRegex(true);
        }else{
            setProblem({["oldPass"]:false, ["newPass"]:false, ["newPassRepeated"]:false });
            setPasswordsEqual(false);
            setPasswordsRegex(false);
            //ovde saljemo na back inputValues.newPass i inputValues.oldPass, pa u zavisnosti sta server vrati ispitujemo
            if(true===true && inputValues.oldPass!=""){
                setOldPasswordCorrect(false)
                props.onPasswordChanged(true);
            }else{
                setProblem({["newPass"]:false, ["newPassRepeated"]:false , ["oldPass"]:true });
                setOldPasswordCorrect(true)
            }
        }
      };

    return(
        <form className="password-change-form">
            <div className="password-change-inputs-conteiner">
                <PasswordChangeInput 
                    placeholder={"Stara šifra"}
                    value={inputValues.oldPass}
                    problem={problem.oldPass}
                    onChange={(e) => handleChange({ name: 'oldPass', value: e.target.value })}
                />
                {oldPasswordCorrect &&(
                    <p>Pogrešno uneta stara šifra. Pokušajte ponovo!</p>
                )
                }
                <PasswordChangeInput placeholder={"Nova šifra"}
                    value={inputValues.newPass}
                    problem={problem.newPass}
                    onChange={(e) => handleChange({ name: 'newPass', value: e.target.value })}
                />
                <PasswordChangeInput placeholder={"Ponovi šifru"}
                
                    value={inputValues.newPassRepeated}
                    problem={problem.newPassRepeated}
                    onChange={(e) => handleChange({ name: 'newPassRepeated', value: e.target.value })}
                />
                {passwordsEqual && (
                    <p>Unete šifre nisu identične. Pokušajte ponovo!</p>
                )}
                {passwordsRegex && (
                    <p>Lozinka mora imati vise od 8 karaktera, mora sadrzati malo, veliko slovo, specijalan karakter i broj</p>
                )}
            </div>
            <div className="password-change-button" onClick={handleSubmit}>
                <p>Sačuvaj novu šifru</p>
            </div>
        </form>
    )
}

export default PasswordChangeForm;