import React from "react";
import { images } from "../../../constants";
import { useTheme } from "@mui/material";

const ProfilePageRow = (props) => {
  const theme = useTheme();
  const backgroundAlt = theme.palette.background.alt;
  const neutralMedium = theme.palette.neutral.medium;
  const neutralLight = theme.palette.neutral.light;
  return (
    <div
      className="flex-direction-row gap-24 align-center width-100 profile-page-row"
      style={{ backgroundColor: backgroundAlt }}
    >
      {!props.training && (
        <div className="profile-page-row-img">
          <img src={images.profilePicture} alt="img" width={34} height={34} />
        </div>
      )}
      <div className="flex-direction-row gap-24 width-100 profile-page-row-text">
        <p className="Grotesk-500 font-16" style={{ color: neutralMedium }}>
          {props.item.name}
        </p>
        <p className="Grotesk-400 font-16" style={{ color: neutralLight }}>
          {props.item.position || props.item.created_date || props.item.date}
        </p>
        {!props.teamMember && (
          <p
            className="Grotesk-400 font-16 hidden-sm"
            style={{ color: neutralLight }}
          >
            {props.item.download_date || props.item.place}
          </p>
        )}
        {props.document && (
          <p
            className="Grotesk-400 font-16 hidden-sm"
            style={{ color: neutralLight }}
          >
            {props.item.size}
          </p>
        )}
      </div>
      {props.teamMember && (
        <div
          className={`profile-page-row-status ${
            props.item.status === "green"
              ? "green"
              : props.item.status === "yellow"
              ? "yellow"
              : "red"
          }`}
        ></div>
      )}
    </div>
  );
};

export default ProfilePageRow;
