import React, { useState } from "react";
import { otherInfoArray } from "../constants";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import OtherInfoRow from "./OtherInfoRow";
import { useTheme } from "@mui/material";
import ModalApp from "../../../components/ModalApp/ModalApp";

const OtherInfo = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  const handleAddInfo = () => {
    setOpenModal(true);
  };
  return (
    <div className="flex-direction-column align-start gap-32 align-stretch width-100 animated">
      <div className="flex-direction-row align-center  width-100 other-info-btn superiors-btn">
        <ButtonApp title="Dodaj" purple onButtonClick={handleAddInfo} />
      </div>
      <p
        className="Grotesk-500 font-16 profile-title"
        style={{ color: neutralMedium }}
      >
        Ostale Informacije
      </p>
      <div className="flex-direction-column align-start gap-24 align-stretch width-100">
        {otherInfoArray.map((item, index) => (
          <OtherInfoRow key={index} item={item} />
        ))}
      </div>
      {openModal && (
        <div className="flex-direction-row align-center justify-center add-notification">
          <ModalApp
            title="Dodaj podatke"
            handleCloseClick={() => setOpenModal(false)}
            profileInfo
            optionsArray={[
              "Velicina",
              "Članovi Porodice",
              "Slava",
              "Vozač",
              "Alergije",
              "Disability",
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default OtherInfo;
