import React, { useState } from "react";
import "./styles/Calendar.css";
import { useTheme } from "@mui/material";
import "../../styles/global.scss";
import { icons } from "../../constants";

const CalendarInput = ({ value, onDateChange, error, label, style }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(value);

  const handleDateSelect = (formattedDate) => {
    setSelectedDate(formattedDate);
    setShowCalendar(false); // Sakrij kalendar
    console.log(formattedDate); // Loguj izabrani datum
    onDateChange(formattedDate);
  };
  const theme = useTheme();

  const neutralMedium = theme.palette.neutral.medium;

  return (
    <div
      className="flex-direction-column gap-12 calendar-input-container"
      style={style}
    >
      {label && (
        <label
          className="Grotesk-500 font-16 weight-500 width-100"
          style={{
            color: neutralMedium,
            textAlign: "start",
            lineHeight: "20px",
          }}
        >
          {label}
        </label>
      )}
      <div className="input-wrapper">
        <input
          type="text"
          placeholder="Izaberite datum"
          value={selectedDate}
          readOnly
          onClick={() => setShowCalendar(!showCalendar)}
          className={`Grotesk-400 font-16 ${error ? "error" : ""}`}
        />
        <button
          className="calendar-icon"
          onClick={() => setShowCalendar(!showCalendar)}
        >
          <img src={icons.calender} alt="kalendar" width={16} height={18} />
        </button>
      </div>
      {showCalendar && (
        <div className="calendar-dropdown">
          <Calendar
            onDateSelect={handleDateSelect}
            selectedDate={selectedDate}
          />
        </div>
      )}
    </div>
  );
};

const Calendar = ({ selectedDate, onDateSelect }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const generateCalendarDays = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const days = [];

    // Dodaj prazne dane za prethodni mesec
    for (let i = 0; i < firstDay; i++) days.push(null);
    // Dodaj dane trenutnog meseca
    for (let day = 1; day <= daysInMonth; day++) days.push(day);

    return days;
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    setCurrentDate(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    setCurrentDate(nextMonth);
  };

  const handleDateClick = (day) => {
    if (!day) return; // Ako je dan prazan, ništa ne radi

    const selectedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );

    // Proveri da li je datum validan
    if (isNaN(selectedDate.getTime())) {
      console.error("Invalid date!");
      return;
    }

    // Formatiraj datum kao yyyy-MM-dd
    const formattedDate = `${selectedDate.getFullYear()}-${String(
      selectedDate.getMonth() + 1
    ).padStart(2, "0")}-${String(selectedDate.getDate()).padStart(2, "0")}`;

    if (onDateSelect) onDateSelect(formattedDate); // Prosledi izabrani datum
  };

  const days = generateCalendarDays(currentDate);

  return (
    <div className="calendar">
      <div className="calendar-header">
        <button onClick={handlePrevMonth}>
          <img src={icons.arrow_left} alt="levo" width={18} height={18} />
        </button>
        <h2 className="Grotesk-400 font-16">
          {currentDate.toLocaleString("default", { month: "long" })}{" "}
          {currentDate.getFullYear()}
        </h2>
        <button onClick={handleNextMonth}>
          <img src={icons.arrow_right} alt="levo" width={18} height={18} />
        </button>
      </div>
      <div className="calendar-days">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="Grotesk-400 font-16 day-name">
            {day}
          </div>
        ))}
        {days.map((day, index) => (
          <div
            key={index}
            className={`Grotesk-400 font-16 date ${day ? "" : "empty"} ${
              selectedDate ===
              `${currentDate.getFullYear()}-${String(
                currentDate.getMonth() + 1
              ).padStart(2, "0")}-${String(day).padStart(2, "0")}`
                ? "selected"
                : ""
            }`}
            onClick={() => day && handleDateClick(day)}
          >
            {day || ""}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarInput;
